import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "../../../../extras/schemas/equipmentLocationNew.schema";
import FormSelect from "../../../../components/forms/formSelect/FormSelect";
import FormInput from "../../../../components/forms/formInput/FormInput";
import { useCities, useStates } from "../../../../extras/services/queries";
import ReactInputMask from "react-input-mask";
import axios from "axios";

const StepLocalization = ({ currentStep, setCurrentStep, setData }) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [cities, setCities] = useState([]);
  const [cepInfo, setCepInfo] = useState(null);

  const { data: states, isPending: isLoadingState } = useStates();
  const { mutate: mutateCity, isPending: isLoadingCity } = useCities();

  const onSubmit = (data) => {
    setData((prevState) => ({ ...prevState, ...data }));
    setCurrentStep((currentStep) => currentStep + 1);
  };

  const getInfosbyCep = (cep) => {
    axios.get(`https://viacep.com.br/ws/${cep}/json/`, { responseType: 'json' }).then((response) => {
      if (response.erro) {
        setCepInfo(null);
        return;
      }

      setCepInfo(response.data);
    });
  }

  useEffect(() => {
    if (!cepInfo)
      return;

    setTimeout(() => {
      const stateId = states?.data?.find(item => item.uf == cepInfo.uf).id;

      if (stateId)
        setValue('en_state_id', stateId);

      setValue('en_address', cepInfo?.logradouro);
      setValue('en_district', cepInfo?.bairro);
      setValue('en_complement', cepInfo?.complemento);
    }, 500)

  }, [JSON.stringify(cepInfo)])

  useEffect(() => {
    const cep = watch('en_cep');
    if (cep?.length == 8)
      getInfosbyCep(cep);

  }, [watch('en_cep')])

  useEffect(() => {
    if (!watch("en_state_id") || isLoadingCity || isLoadingState)
      return;

    mutateCity(watch("en_state_id"), {
      onSuccess: (city) => {
        setCities(city?.data);
        const stateIdByApi = states?.data?.find(item => item.uf == cepInfo.uf).id;

        if (stateIdByApi == watch("en_state_id")) {
          const cityId = city?.data?.find(objeto => objeto.name.toLowerCase() === cepInfo.localidade.toLowerCase()).id;

          if (cityId)
            setValue("en_city_id", cityId);

          return;
        }

        setValue("en_city_id", "")
      }
    })
  }, [watch("en_state_id")])

  return (
    <div className="mt-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid lg:grid-cols-3 lg:gap-5">
          <label className="mb-3 block">
            <span className="font-bold text-slate-700 mb-1 inline-block text-sm ml-1 dark:text-white">
              CEP
            </span>
            <ReactInputMask maskChar={null} onChange={(res) => setValue('en_cep', res.target.value.replace('-', ''))} mask="99999-999" value={getValues('en_cep')} className="py-3 px-4 dark:text-white text-slate-700 dark:bg-transparent placeholder:text-slate-300 dark:border-slate-800 outline-none border border-slate-100 rounded-lg w-full focus:border-[#06B0B4] dark:focus:border-[#06B0B4] focus:shadow-[0px_0px_0px_5px_rgba(6,_176,_180,_0.1)]" />
            {errors?.en_cep?.message && (
              <span className="text-red-500 text-sm">{errors?.en_cep?.message}</span>
            )}
          </label>
          <FormSelect
            label="Estado"
            name="en_state_id"
            register={register}
            error={errors?.en_state_id}
            options={states?.data?.map(item => ({
              label: `${item.uf} - ${item.name}`,
              value: item.id
            }))}
            isLoading={isLoadingState}
          />
          <FormSelect
            label="Cidade"
            options={cities?.map(item => ({
              label: item.name,
              value: item.id
            }))}
            name="en_city_id"
            register={register}
            error={errors?.en_city_id}
            isLoading={isLoadingCity}
          />
        </div>
        <div className="grid lg:grid-cols-2 lg:gap-5">
          <FormInput
            label="Endereço"
            placeholder="Insira o endereço"
            name="en_address"
            register={register}
            error={errors?.en_address}
          />
          <FormInput
            label="Número"
            placeholder="Insira o número"
            name="en_number"
            register={register}
            error={errors?.en_number}
          />
        </div>
        <FormInput
          label="Bairro"
          placeholder="Insira o bairro"
          name="en_district"
          register={register}
          error={errors?.en_district}
        />
        <FormInput
          label="Complemento"
          placeholder="Insira o complemento"
          name="en_complement"
          register={register}
        />
        <button
          type="submit"
          className="font-medium bg-[#002772] text-white px-7 py-2 rounded-full hover:opacity-80 mt-1 mb-1"
        >
          Próximo
        </button>
      </form>
    </div>
  );
};

export default StepLocalization;
