import React, { useEffect, useState } from "react";

import { Bell, Menu, Search } from "lucide-react";
import useDrawer from "../../../extras/providers/DrawerProvider";
import { getCookie } from "react-use-cookie";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../../../extras/providers/AuthContext";

// import { Menu } from "lucide-react";

const Header = ({ icon, title }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { open, setOpen } = useDrawer();
  const { user } = useAuth();

  return (
    <div className="px-5 h-14 flex flex-row items-center bg-white shadow-sm dark:bg-slate-950/50 md:bg-transparent md:shadow-none z-20">
      <button
        className="inline-block md:hidden mr-7 hover:bg-slate-100 dark:hover:bg-slate-900/50 dark:text-white rounded-full p-1"
        onClick={() => setOpen(!open)}
      >
        <Menu />
      </button>
      <div className="w-full flex flex-row items-center justify-between">
        <div className="w-full flex flex-row">
          <span className="text-blue-900 dark:text-[#06b0b4]">{icon}</span>
          <h1 className="font-medium text-md text-gray-800 dark:text-white ml-3">
            {title}
          </h1>
        </div>

        <div className="flex flex-row items-center shrink-0">
          <div className="hidden md:flex flex-row gap-6">
            <button className="text-slate-400 dark:text-slate-700">
              <Search />
            </button>
            <button className="text-slate-400 dark:text-slate-700">
              <Bell />
            </button>
          </div>

          <div className="hidden md:inline-block border-l border-slate-200 dark:border-slate-900 ml-6 mr-6 h-6" />

          {/* Profile button */}
          <div className="relative">
            <button
              className="flex flex-row items-center shrink-0"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <span className="font-medium text-blue-950 dark:text-white hidden md:inline-block">
                {user?.name}
              </span>
              <div
                className={`w-10 h-10 ml-5 rounded-full ${openMenu
                  ? "border-2 border-blue-900 dark:border-[#06b0b4]"
                  : ""
                  } flex flex-row items-center justify-center shrink-0`}
              >
                <div className="w-8 h-8 rounded-full overflow-hidden relative">
                  <img
                    className="mx-auto w-full h-full object-cover"
                    src="https://cdn.vox-cdn.com/thumbor/WYZNb2sx7XuDb9ALk49LQtjF_IQ=/0x0:4000x2781/1400x1400/filters:focal(2000x1391:2001x1392)/cdn.vox-cdn.com/uploads/chorus_asset/file/22520460/1229901686.jpg"
                    alt="Imagem de perfil"
                  />
                </div>
              </div>
            </button>

            {openMenu && (
              <div className="w-[220px] right-0 bg-white dark:bg-slate-800 absolute mt-2 rounded-md shadow-md">
                <ul className="flex flex-col py-2">
                  <li>
                    <a
                      href="/"
                      className="block md:hidden px-4 py-1 hover:bg-slate-50 text-gray-900 dark:text-white dark:hover:bg-slate-700"
                    >
                      Pesquisar
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="block md:hidden px-4 py-1 hover:bg-slate-50 text-gray-900 dark:text-white dark:hover:bg-slate-700"
                    >
                      Notificações
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="px-4 py-1 block hover:bg-slate-50 text-gray-900 dark:text-white dark:hover:bg-slate-700"
                    >
                      Meu perfil
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="px-4 py-1 block hover:bg-slate-50 text-gray-900 dark:text-white dark:hover:bg-slate-700"
                    >
                      Sair
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
};

export default Header;
