import React from "react";

import Icon from "../icon/Icon";
import useTheme from "../../../../extras/hooks/useTheme";

const options = [
  {
    theme: "light",
    iconName: "Sun",
  },
  {
    theme: "dark",
    iconName: "Moon",
  },
  {
    theme: "system",
    iconName: "Monitor",
  },
];

const ThemeSwitcher = () => {
  const { theme, setTheme } = useTheme();

  const isActive = (themeOption) =>
    themeOption === theme
      ? "text-cyan-500 fill-[#06b0b4]"
      : "text-slate-400 fill-none";

  return (
    <div className="bg-gray-50 dark:bg-slate-800/25 rounded-full flex flex-row items-center justify-between px-2 py-1 mx-5 mb-5">
      <span className="ml-2 text-slate-400">
        Tema
      </span>
      <div className="flex px-1">
        {options.map((item, index) => {
          return (
            <button
              key={index}
              onClick={() => setTheme(item.theme)}
              className={`p-2 ${isActive(item.theme)}`}
            >
              <Icon name={item.iconName} />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ThemeSwitcher;
