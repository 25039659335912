import * as yup from "yup";

const updateBranchSchema = yup.object().shape({
    name: yup.string().required("Campo obrigatório"),
    en_zipcode: yup.string().required("Campo obrigatório"),
    en_address: yup.string().optional(),
    en_number: yup.string().optional(),
    en_complement: yup.string().optional(),
    en_district: yup.string().optional(),
    en_city_id: yup.string().required("Campo obrigatório"),
    en_state_id: yup.string().required("Campo obrigatório"),
    bank: yup.string().optional(),
    bank_branch: yup.string().optional(),
    bank_actype: yup.string().optional(),
    bank_acnumber: yup.string().optional(),
    bank_recipientname: yup.string().optional(),
    bank_recipientdoc: yup.string().optional(),
});

export default updateBranchSchema;
