import React from "react";

const Viaconsulti = () => {
  return (
    <div className="px-5">
      <div className="overflow-x-auto w-full bg-white dark:bg-slate-900 shadow-sm rounded-md py-4 px-5">
        Via Consulti
      </div>
    </div>
  );
};

export default Viaconsulti;
