import { ChevronLeft, ChevronRight } from "lucide-react";
import { useCallback, useState } from "react";

function Button({ content, onClick, active, disabled }) {
    return (
        <button
            className={`flex flex-col cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
      ${active ? "bg-blue-500 text-white dark:bg-navy-900" : "text-blue-500"}
      ${
          !disabled
              ? "hover:bg-blue-500 hover:text-white dark:bg-navy-700 dark:text-white dark:hover:bg-white/20"
              : "text-blue-300 cursor-not-allowed dark:bg-white/20 dark:text-white"
      }
      `}
            onClick={onClick}
            disabled={disabled}
        >
            {content}
        </button>
    );
}

function Pagination({
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
    visiblePageCount
}) {
    const renderPageLinks = useCallback(() => {
        if (pageCount === 0) return null;
        const visiblePageButtonCount = (visiblePageCount) ? visiblePageCount : 10;
        let numberOfButtons =
            pageCount < visiblePageButtonCount
                ? pageCount
                : visiblePageButtonCount;
        const pageIndices = [pageIndex];
        numberOfButtons--;
        [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
            const pageNumberBefore = pageIndices[0] - 1;
            const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;

            if (
                pageNumberBefore >= 0 &&
                (itemIndex < numberOfButtons / 2 ||
                    pageNumberAfter > pageCount - 1)
            ) {
                pageIndices.unshift(pageNumberBefore);
            } else {
                pageIndices.push(pageNumberAfter);
            }
        });
        return pageIndices.map((pageIndexToMap) => (
            <li key={pageIndexToMap}>
                <Button
                    content={pageIndexToMap + 1}
                    onClick={() => gotoPage(pageIndexToMap)}
                    active={pageIndex === pageIndexToMap}
                />
            </li>
        ));
    }, [pageCount, pageIndex]);
    return (
        <ul className="flex gap-2">
            <li>
                <Button
                    content={
                        <div className="flex ml-1">
                            <ChevronLeft size="0.6rem" />
                            <ChevronLeft
                                size="0.6rem"
                                className="-translate-x-1/2"
                            />
                        </div>
                    }
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                />
            </li>
            {renderPageLinks()}
            <li>
                <Button
                    content={
                        <div className="flex ml-1">
                            <ChevronRight size="0.6rem" />
                            <ChevronRight
                                size="0.6rem"
                                className="-translate-x-1/2"
                            />
                        </div>
                    }
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                />
            </li>
        </ul>
    );
}

export { Pagination };
