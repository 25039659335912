import React, { useCallback, useState } from "react";

import { Star, Trash2, Upload } from "lucide-react";
import { useDropzone } from "react-dropzone";

import { v4 as uuidv4 } from "uuid";

const SingleImage = ({ selectedImage, customIndex, setSelectedImage, title, mainPicture, setMainPicture }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setSelectedImage(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          uuid: uuidv4(),
        }),
      ),
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg"],
    },
    maxFiles: 1,
    multiple: false,
  });

  const classes = isDragActive
    ? "cursor-pointer min-h-[200px] max-h-[200px] rounded-2xl overflow-hidden bg-cyan-500/5 dark:bg-cyan-600/5 border-2 border-cyan-500 border-dashed dark:bg-slate-700 hover:opacity-90 text-sm text-slate-800 flex flex-col gap-4 items-center justify-center"
    : "cursor-pointer min-h-[200px] rounded-2xl overflow-hidden bg-slate-50 dark:bg-slate-800 hover:opacity-90 text-sm text-slate-800 flex flex-col gap-4 items-center justify-center";

  const removeImage = () => {
    setSelectedImage("");
  };

  return (
    <div className="flex flex-col">
      {/* Imagem de capa */}
      <h1 className="text-slate-700 dark:text-white font-normal text-md mb-3">
        {title}
      </h1>

      <div className="">
        {selectedImage && (
          <div className="group relative min-h-[200px] rounded-2xl overflow-hidden bg-slate-50 dark:bg-slate-800 flex flex-row items-center justify-center">
            {" "}
            {/* Preview imagem */}
            <img
              className="h-full w-full object-cover object-center absolute"
              src={selectedImage[0].preview}
              alt="Imagem do equipamento"
            />
            <div className="hidden w-full h-full group-hover:flex flex-row items-center justify-center bg-slate-900/50 absolute">
              <div className="top-0 absolute">
                <div onClick={() => setMainPicture(customIndex)} className="p-2 cursor-pointer">
                  <Star className={`${mainPicture == customIndex && 'text-yellow-500'} hover:text-yellow-500`} />
                </div>
              </div>
              <button
                onClick={removeImage}
                className="w-[100px] h-[100px] rounded-full flex flex-row items-center justify-center bg-slate-900/50 hover:bg-slate-900/70 text-white"
              >
                <Trash2 />
              </button>
            </div>
          </div>
        )}

        {!selectedImage && (
          <div {...getRootProps()} className={classes}>
            {" "}
            {/* Preview imagem */}
            <Upload className="text-cyan-600" />
            <p className="text-center dark:text-white">
              Arraste a imagem, ou então <br />{" "}
              <span className="underline font-medium text-cyan-600">
                clique aqui
              </span>{" "}
              para selecionar.
            </p>
            <p className="text-center dark:text-slate-600 text-slate-400">
              Somente formatos: <br /> .png, .jpg, .jpeg
            </p>
            <input {...getInputProps()} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleImage;
