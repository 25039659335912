import { Check } from "lucide-react";
import React from "react";
import { motion } from "framer-motion";

// currentStep={currentStep} step={step}

const StepItem = ({ currentStep, step, index }) => {
  const isCurrent = currentStep === index;
  const isPrevious = index < currentStep;

  const indexClass = isCurrent
    ? "bg-[#06B0B4] text-white text-md font-medium w-10 h-10 flex flex-row items-center justify-center rounded-full"
    : isPrevious
    ? "bg-green-500 text-white text-md font-medium w-10 h-10 flex flex-row items-center justify-center rounded-full"
    : "bg-slate-50 text-slate-300 text-md font-medium w-10 h-10 flex flex-row items-center justify-center rounded-full";

  const titleClass = isCurrent
    ? "text-sm text-[#06B0B4] font-medium"
    : isPrevious
    ? "text-sm text-green-500 font-medium"
    : "text-sm text-slate-300 font-medium";

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex flex-col items-center justify-center gap-2 lg:flex-row"
    >
      <div className={indexClass}>{isPrevious ? <Check /> : index + 1}</div>
      <span className={titleClass}>{step}</span>
    </motion.div>
  );
};

export default StepItem;
