import React from "react";

import Acessos from "../../../extras/imgs/acessos.png";

const Header = () => {
  return (
    <div className="flex flex-col md:flex-row p-5 gap-2 md:gap-10 md:items-center">
      <div>
        <img src={Acessos} alt="Imagem de referência de estoque na página" />
      </div>
      <div className="flex flex-col gap-3 md:self-end pb-2">
        <h2 className="font-bold text-[#002772] dark:text-[#06B0B4]">
          O que posso fazer aqui?
        </h2>
        <p className="max-w-[600px] text-slate-600 dark:text-white">
          Gerencie o acesso e o que cada perfil pode fazer no sistema.
        </p>
      </div>
    </div>
  );
};

export default Header;
