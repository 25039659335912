import * as yup from "yup";

const schema = yup.object().shape({
  sector: yup.number().required("Selecione uma opção"),
  category: yup.number().required("Selecione uma opção"),
  manufacturer: yup.number().required("Selecione uma opção"),
  model: yup.number().required("Modelo é obrigatória"),
  serial_number: yup.string().required("Número de série é obrigatório"),
  horse_power: yup.string().required("A potência do motor é obrigatória"),
  year: yup.string().required("Informe o ano do equipamento."),
  engine_hours: yup.string().required("Horas trabalhadas é obrigatória"),
  price: yup.string().required("Preço é obrigatório."),
  preservation: yup
    .string()
    .required("Estado de conservação é obrigatório."),
  description: yup.string().required("A descrição é obrigatória."),
  name: yup.string().required("O nome é obrigatório.")
});

export default schema;
