import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { getCookie } from "react-use-cookie";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    timeout: 100000
});

export const useHttp = () => {
    const request = async (configObject) => {
        const { url, method, data } = configObject;

        let bearerToken = "";

        try {
            bearerToken = getCookie("authorization");
        } catch (e) {
            new Error(e);
        }

        const options = {
            url: url,
            method: method.toLowerCase(),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + bearerToken,
                "Token-Access": process.env.REACT_APP_BASE_API_KEY,
            },
            data: data
        };

        try {
            const response = await instance(options);

            return response;
        } catch (e) {
            let error = new Error();
            Object.assign(error, { response: e.response });

            throw error;
        }
    }

    return request;
}