import React from "react";

import { Content } from "../../../components/layout";

import { DollarSign } from "lucide-react";
import Header from "./Header";
import { Link } from "react-router-dom";
import ColumnTable from "../../../components/tables/ColumnTable";
import { useProduct } from "../../../extras/services/queries";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import Labels from "../stock/labels/Labels";

const Sales = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => <td className="w-full px-3 py-2 font-medium text-md dark:text-white">Equipamento</td>,
      cell: (info) => {
        var now = moment(new Date()); //todays date
        var end = moment(info?.row.original.create_time); // another date
        var duration = moment.duration(now.diff(end));
        var days = duration.asDays();

        if (days >= 0 && days <= 5)
          info.row.original.colorBorder = "#06B0B4";
        else if (days > 5 && days <= 90)
          info.row.original.colorBorder = "#D3C012";
        else if (days > 90 && days <= 180)
          info.row.original.colorBorder = "#FF891D";
        else if (days > 180)
          info.row.original.colorBorder = "#B10808";

        return (
          <td className="px-4 py-3 w-[30%] align-top">
            <div className="flex flex-row gap-5 shrink-0">
              <div className="w-[63px] h-[63px] rounded-lg bg-slate-50 dark:bg-slate-700 shrink-0" />

              <div className="flex flex-col gap-1 ">
                <Link to={`/estoque/${info.row.original.id}`} className="dark:text-white">
                  {info.getValue()} -
                  ANO {info.row.original.year}
                </Link>
                <div className="flex flex-row gap-5">
                  <button className="text-cyan-500 font-medium">
                    Ativado
                  </button>
                  <button className="text-cyan-500 font-medium">
                    Leilão #49906
                  </button>
                </div>
              </div>
            </div>
          </td>
        )
      },
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: () => <td className="w-full px-3 py-2 font-medium text-md dark:text-white">Descrição</td>,
      cell: (info) => (
        <td className="px-4 py-3 align-top text-left dark:text-white">
          <p>
            <span className="font-medium">Código:</span> {info?.row?.original?.id}
          </p>
          <p>
            <span className="font-medium">Categoria:</span>{" "}
            {info?.row?.original?.sector?.name}/{info?.row?.original?.categorie?.name}
          </p>
          <p>
            <span className="font-medium">Cadastro:</span> {moment(info?.row.original.create_time).format('DD/MM/YYYY')} às {moment(info?.row.original.create_time).format('hh:mm')}
          </p>
          <p>
            <span className="font-medium">Cadastrado {moment(info?.row.original.create_time).fromNow()}</span>
          </p>
        </td>
      ),
    }),
    columnHelper.accessor("city", {
      id: "city",
      header: () => null,
      cell: (info) => {
        const price = (info.row.original.price) ? info.row.original.price : 0;
        return (
          <td className="px-4 py-3 align-top text-right">
            <h2 className="font-medium text-4xl text-blue-900 dark:text-[#06B0B4]">
              R$ {Number(price).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              })}
            </h2>
            <p className="dark:text-slate-500">
              {info.getValue()?.name}/{info.row?.original?.state?.uf}
            </p>
          </td>
        )
      },
    })]

  return (
    <Content title="Vendas" icon={<DollarSign />}>
      <div className="p-5 text-slate-900 dark:text-white max-w-[100vh]">
        <Header />
        <div className="w-full bg-white dark:bg-slate-900 shadow-sm rounded-md p-5 min-w-[1300px]">
          <div className="flex flex-col lg:flex-row items-left lg:items-center justify-between mb-4">
            <span className="font-medium mb-2 lg:mb-0 text-slate-700 dark:text-white">
              Vendas
            </span>
            <Labels />
          </div>
          <div className="mt-5 overflow-x-auto">
            <ColumnTable
              styleType="borded"
              title="Equipamentos vendidos"
              columnsData={columns}
              customParams={{ sold: 1 }}
              route={useProduct} />
          </div>
        </div>
      </div>
    </Content >
  );
};

export default Sales;
