import { useState } from "react";
import { FileWarning, X } from "lucide-react";
import Modal from "../modal/Modal";

const ConfirmAction = (props) => {
    const { callBackOpenConfirm, onClick, className = '', children, confirmButtonTitle = 'Sim, confirmar', cancelButtonTitle = 'Cancelar', textConfirm = 'Você tem certeza que gostaria de fazer isso?', titleConfirm = 'Atenção', color = 'gray', confirmColor = 'red' } = props;
    const [modalConfirm, setModalConfirm] = useState(false);

    const onClickConfirm = () => {
        if (onClick)
            onClick();

        setModalConfirm(false);
    }

    const closeModal = () => {
        setModalConfirm(false);
    }

    const openConfirmation = () => {
        if (callBackOpenConfirm)
            callBackOpenConfirm();

        setModalConfirm(true);
    }

    return (
        <>
            <div className={`cursor-pointer ${className}`} onClick={() => openConfirmation()}>
                {children}
            </div>
            <Modal show={modalConfirm} onClose={closeModal} maxWidth="md">
                <div className="p-3 text-center">
                    <div className="flex justify-center mb-4">
                        <div className={`rounded-full bg-${confirmColor}-100 dark:bg-custom-500/20 p-3`}>
                            <FileWarning className={`text-${confirmColor}-500`} />
                        </div>
                    </div>
                    <h2 className="text-lg font-medium text-gray-900 dark:text-white">
                        {titleConfirm}
                    </h2>
                    <p className="mt-1 text-sm text-gray-600 dark:text-white" dangerouslySetInnerHTML={{ __html: textConfirm }}>
                    </p>
                    <div className="mt-6 flex justify-center gap-2">
                        <button
                            onClick={() => closeModal()}
                            className={`font-medium bg-gray-600 text-white px-7 py-2 rounded-full hover:opacity-80`}
                        >
                            {cancelButtonTitle}
                        </button>
                        <button
                            onClick={() => onClickConfirm()}
                            className="font-medium bg-[#002772] text-white px-7 py-2 rounded-full hover:opacity-80"
                        >
                            {confirmButtonTitle}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ConfirmAction;