import * as yup from "yup";

const updateUserSchema = yup.object().shape({
    name: yup.string().required("Campo obrigatório"),
    email: yup.string().required("Campo obrigatório"),
    password: yup.string().required("Campo obrigatório"),
    dealership_group_id: yup.string().required("Campo obrigatório"),
    dealership_branch_id: yup.lazy((value) => {
        if (value !== undefined && value !== "" && !isNaN(value)) {
            return yup.number();
        }
        return yup.number().transform((value) => Number.isNaN(value) ? 0 : value ).nullable().optional();
    }),
});

export default updateUserSchema;
