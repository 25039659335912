import React from "react";

import { Content } from "../../../components/layout";

import { FilePieChart } from "lucide-react";
import Header from "./Header";
import ReportsItem from "./ReportsItem";

const Reports = () => {
  return (
    <Content title="Relatórios" icon={<FilePieChart />}>
      <div className="p-5 text-slate-900 dark:text-white">
        <Header />
        <div className="w-full bg-white dark:bg-slate-900 shadow-sm rounded-md p-5">
          <span className="pl-7 pr-3 py-3 text-slate-400 text-sm mb-5 block">
            Relatórios disponíveis
          </span>

          <ul className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <ReportsItem title="Listar todos" action={() => {}} />
            <ReportsItem title="Apenas ativados" action={() => {}} />
            <ReportsItem title="Apenas desativados" action={() => {}} />
            <ReportsItem title="Apenas vendidos" action={() => {}} />
            <ReportsItem title="Apenas não vendidos" action={() => {}} />
            <ReportsItem title="Tempo de estoque" action={() => {}} />
            <ReportsItem title="Relatório mensal analítico" action={() => {}} />
            <ReportsItem title="Relatório consolidado" action={() => {}} />
          </ul>
        </div>
      </div>
    </Content>
  );
};

export default Reports;
