import { useEffect, useState } from "react";
import Modal from "../../../../../components/modal/Modal";
import FormInput from "../../../../../components/forms/formInput/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormSelect from "../../../../../components/forms/formSelect/FormSelect";
import Button from "../../../../../components/button/Button";
import { useCities, useGetBranch, useStates, useUpdateBranch } from "../../../../../extras/services/queries";
import { X } from "lucide-react";
import CardHide from "../../../../../components/forms/cardHide/CardHide";
import updateBranchSchema from "../../../../../extras/schemas/update-branch.schema";

const ModalUpdate = (props) => {
    const { show, setShow, branchId } = props;
    const [cities, setCities] = useState([]);
    const { mutate: mutateBranch, isLoading } = useGetBranch();
    const { mutate } = useUpdateBranch();
    const { data: states, isPending: isLoadingState } = useStates();
    const { mutate: mutateCity, isPending: isLoadingCity } = useCities();

    const closeModal = () => {
        setShow(false);
    };

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(updateBranchSchema),
    });

    const onSubmit = (data) => {
        //TODO: Imprimir erro/sucesso

        mutate({ ...data, branch_id: branchId }, {
            onSuccess: (res) => {
                console.log(res);
            },
            onError: (res) => {
                console.log(res)
            }
        })
    }

    useEffect(() => {
        mutateBranch({ branch_id: branchId }, {
            onSuccess: (res) => {
                if (res?.data['en_state_id'] > 0) {
                    mutateCity(res?.data['en_state_id'], {
                        onSuccess: (city) => {
                            setCities(city?.data);
                            setTimeout(() => {
                                setValue("en_city_id", res?.data['en_city_id'])
                            }, 500)
                        }
                    })
                }

                Object.keys(res?.data).forEach(chave => {
                    setValue(chave, res?.data[chave]);
                });
            }
        })
    }, [branchId])

    useEffect(() => {
        if (!watch("en_state_id") || isLoadingCity || isLoadingState)
            return;

        mutateCity(watch("en_state_id"), {
            onSuccess: (city) => {
                setCities(city?.data);
            }
        })
    }, [watch("en_state_id")])


    return (
        <Modal show={show} onClose={closeModal}>
            <div className="p-3">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-medium text-gray-900 dark:text-white ">
                        Editando filial
                    </h2>
                    <div onClick={() => closeModal()} className="cursor-pointer">
                        <X />
                    </div>
                </div>
                <p className="mt-1 text-sm text-gray-600 mb-2">
                    Atualize os dados da filial
                </p>
                <form onSubmit={handleSubmit(onSubmit)} className="grid lg:grid-cols-3 gap-2">
                    <div className="col-span-3">
                        <FormInput
                            className="col-span-2"
                            label="Nome"
                            placeholder="Digite aqui..."
                            name="name"
                            register={register}
                            error={errors?.name}
                            required
                        />
                    </div>
                    <CardHide title="Endereço" className="col-span-3" defaultOpen>
                        <FormInput
                            label="CEP"
                            placeholder="0000-00"
                            name="en_zipcode"
                            register={register}
                            error={errors?.en_zipcode}
                            required
                        />
                        <FormSelect
                            label="Estado"
                            name="en_state_id"
                            register={register}
                            isLoading={isLoadingState}
                            error={errors?.en_state_id}
                            options={states?.data?.map(item => ({
                                label: `${item.uf} - ${item.name}`,
                                value: item.id
                            }))}
                            required
                        />
                        <FormSelect
                            label="Cidade"
                            name="en_city_id"
                            register={register}
                            isLoading={isLoadingCity}
                            error={errors?.en_city_id}
                            options={cities?.map(item => ({
                                label: item.name,
                                value: item.id
                            }))}
                            required
                        />

                        <FormInput
                            label="Endereço"
                            name="en_address"
                            register={register}
                            error={errors?.en_address}
                        />

                        <FormInput
                            label="Número"
                            name="en_address"
                            register={register}
                            error={errors?.en_address}

                        />
                        <FormInput
                            label="Bairro"
                            name="en_district"
                            register={register}
                            error={errors?.en_district}

                        />
                        <div className="col-span-2">
                            <FormInput
                                label="Complemento"
                                name="en_complement"
                                register={register}
                                error={errors?.en_complement}

                            />
                        </div>
                    </CardHide>
                    <CardHide title="Dados bancários" className="col-span-3">
                        <FormInput
                            label="Banco"
                            placeholder="Banco S/A"
                            name="bank"
                            register={register}
                            error={errors?.bank}
                        />
                        <FormSelect
                            label="Tipo de conta"
                            name="bank_actype"
                            register={register}
                            error={errors?.bank_actype} />
                        <FormInput
                            label="Agência"
                            name="bank_branch"
                            register={register}
                            error={errors?.bank_branch} />
                        <FormInput
                            label="Conta"
                            name="bank_acnumber"
                            register={register}
                            error={errors?.bank_acnumber}
                        />

                        <FormInput
                            label="Nome do titular"
                            name="bank_recipientname"
                            register={register}
                            error={errors?.bank_recipientname}
                        />
                        <FormInput
                            label="CPF/CNPJ do Titular"
                            name="bank_recipientdoc"
                            register={register}
                            error={errors?.bank_recipientdoc}
                        />
                    </CardHide>
                    <div className="col-span-3 w-full flex items-end justify-end">
                        <Button loading={isLoading}>Salvar</Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ModalUpdate;