import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { LayoutBase } from "../../components/layout";

import {
  Dashboard,
  Documents,
  ManageAccesses,
  OpportunityCost,
  Reports,
  Sales,
  Stock,
  Configs,
  Edit,
} from "../logged";

import { AnimatePresence } from "framer-motion";
import StockTemplate from "../logged/stockTemplate/StockTemplate";
import StockDetails from "../logged/stockDetails/StockDetails";
import EditTemplate from "../logged/stockDetails/editTemplate/EditTemplate";
import EditDescription from "../logged/stockDetails/editDescription/EditDescription";
import EditEquipmentLocation from "../logged/stockDetails/editEquipmentLocation/EditEquipmentLocation";
import EditPhotos from "../logged/stockDetails/editPhotos/EditPhotos";
import EditAtributtes from "../logged/stockDetails/editAtributtes/EditAtributtes";
import MarkAsSold from "../logged/stockDetails/markAsSold/MarkAsSold";
import Estatistics from "../logged/stockDetails/estatistics/Estatistics";
import Viaconsulti from "../logged/stockDetails/viaconsulti/Viaconsulti";
import InAuction from "../logged/stockDetails/inAuction/InAuction";
import SoldDetails from "../logged/stockDetails/soldDetails/SoldDetails";
import MakeUnavailable from "../logged/stockDetails/makeUnavailable/MakeUnavailable";
import NewEquipment from "../logged/newEquipment/NewEquipment";
import Signin from "../auth/signin/Signin";
import Notes from "../logged/documents/notes/Notes";
import Autorization from "../logged/documents/autorization/Autorization";
import AuctionAnnouncement from "../logged/documents/auctionAnnouncement/AuctionAnnouncement";
import Contract from "../logged/documents/contract/Contract";
import Manual from "../logged/documents/manual/Manual";
import PaymentReceipts from "../logged/documents/paymentReceipts/PaymentReceipts";
import Accounts from "../logged/manageAccesses/accounts/Accounts";
import Filiais from "../logged/manageAccesses/filiais/Filiais";
import Groups from "../logged/manageAccesses/groups/Groups";
import { AuthProvider } from "../../extras/providers/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ProtectedRoute from "./ProtectedRoute";
import Videos from "../logged/videos/Videos";

const queryClient = new QueryClient();

const AppRoutes = () => {
  const location = useLocation();

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/login" element={<Signin />} />
            <Route path="/" element={<ProtectedRoute element={<LayoutBase />} />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="*" element={<Navigate to="dashboard" />} />
              <Route path="/" element={<Navigate to="dashboard" />} />
              <Route path="estoque" element={<StockTemplate />}>
                <Route index element={<Stock />} />
                <Route path=":id" element={<StockDetails />}>
                  <Route path="editar" element={<EditTemplate />}>
                    <Route path="informacoes-essenciais" element={<Edit />} />
                    <Route path="descricao" element={<EditDescription />} />
                    <Route
                      path="local-do-equipamento"
                      element={<EditEquipmentLocation />}
                    />
                    <Route path="fotos" element={<EditPhotos />} />
                    <Route path="atributos" element={<EditAtributtes />} />
                  </Route>
                  {/* <Route path="ofertas-recebidas" element={<Edit />} /> */}
                  <Route path="viaconsulti" element={<Viaconsulti />} />
                  <Route path="estatisticas" element={<Estatistics />} />
                  <Route path="em-leilao" element={<InAuction />} />
                  <Route path="detalhes-da-venda" element={<SoldDetails />} />
                  <Route path="tornar-indisponivel" element={<MakeUnavailable />} />
                  <Route path="marcar-como-vendido" element={<MarkAsSold />} />
                </Route>
              </Route>
              <Route path="cadastrar-equipamento" element={<NewEquipment />} />
              <Route path="videos" element={<Videos />} />
              <Route path="vendas" element={<Sales />} />
              <Route path="documentos" element={<Documents />}>
                <Route index element={<Notes />} />
                <Route path="autorizacao" element={<Autorization />} />
                <Route path="edital" element={<AuctionAnnouncement />} />
                <Route path="contrato" element={<Contract />} />
                <Route path="manuais" element={<Manual />} />
                <Route path="comprovantes" element={<PaymentReceipts />} />
              </Route>
              <Route path="relatorios" element={<Reports />} />
              <Route path="gerenciar-acessos" element={<ManageAccesses />}>
                <Route index element={<Accounts />} />
                <Route path="filiais" element={<Filiais />} />
                <Route path="grupos" element={<Groups />} />
              </Route>
              <Route path="custo-de-oportunidade" element={<OpportunityCost />} />
              <Route path="configuracoes" element={<Configs />} />
            </Route>
          </Routes>
        </AnimatePresence>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default AppRoutes;
