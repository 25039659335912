import React from "react";

import links from "./links";
import { NavLink, useLocation } from "react-router-dom";
import ThemeSwitcher from "../themeSwitcher/ThemeSwitcher";
import { useNavigate } from "react-router-dom";
import { Plus } from "lucide-react";

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isActiveLink = (linkTo) => {
    const pathArray = location.pathname.split("/")
    return (pathArray.length > 0 && pathArray[1] === linkTo.replace("/", ""))
      ? "flex flex-row px-5 py-[12px] pl-7 border-l-4 border-[#002772] dark:border-white text-[#06b0b4] hover:bg-slate-50 dark:hover:bg-slate-50/5"
      : "flex flex-row px-5 py-[12px] pl-7 border-l-4 border-transparent text-slate-400 hover:bg-slate-50 dark:hover:bg-slate-50/5";
  }
  const isActiveLinkName = (linkTo) => {
    const pathArray = location.pathname.split("/")
    return (pathArray.length > 0 && pathArray[1] === linkTo.replace("/", ""))
      ? "ml-6 text-[#002772] dark:text-white font-medium text-md"
      : "ml-6 text-slate-400 font-normal";
  }

  return (
    <nav className="mt-20 h-full overflow-y-auto flex flex-col justify-between">
      <ul>
        {links.map((link, i) => {
          return (
            <li key={i}>
              <NavLink to={link.to} className={isActiveLink(link.to)}>
                {link.icon}
                <span className={isActiveLinkName(link.to)}>{link.name}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
      <div>
        <div className="px-5">
          <button
            onClick={() => navigate("cadastrar-equipamento")}
            className="flex flex-row items-center justify-center bg-[#06b0b4] mt-8 text-white text-sm font-medium w-full h-12 rounded-full hover:opacity-90 mb-3"
          >
            <Plus size={16} />
            <span className="ml-1">Cadastrar Equipamento</span>
          </button>
        </div>

        <ThemeSwitcher />
      </div>
    </nav>
  );
};

export default Menu;
