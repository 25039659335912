import { useEffect, useState } from "react";
import Modal from "../../../../../components/modal/Modal";
import FormInput from "../../../../../components/forms/formInput/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import createUserSchema from "../../../../../extras/schemas/create-user.schema";
import FormSelect from "../../../../../components/forms/formSelect/FormSelect";
import Button from "../../../../../components/button/Button";
import { useCreateUser, useGetUser, useUpdateUser } from "../../../../../extras/services/queries";
import { X } from "lucide-react";
import updateUserSchema from "../../../../../extras/schemas/update-user.schema";

const ModalUpdate = (props) => {
    const { show, setShow, userId } = props;
    const { mutate } = useUpdateUser();
    const { mutate: mutateUser, isLoading } = useGetUser();
    const closeModal = () => {
        setShow(false);
    };

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(updateUserSchema),
    });

    const onSubmit = (data) => {
        //TODO: Imprimir erro/sucesso

        mutate({ ...data, user_id: userId }, {
            onSuccess: (res) => {
                console.log(res);
            },
            onError: (res) => {
                console.log(res)
            }
        })
    }

    useEffect(() => {
        mutateUser({ user_id: userId }, {
            onSuccess: (res) => {
                Object.keys(res?.data).forEach(chave => {
                    setValue(chave, res?.data[chave]);
                });
            }
        })
    }, [userId])

    return (
        <Modal show={show} onClose={closeModal}>
            <div className="p-3">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-medium text-gray-900 dark:text-white ">
                        Editar usuário ({userId})
                    </h2>
                    <div onClick={() => closeModal()} className="cursor-pointer">
                        <X />
                    </div>
                </div>
                <p className="mt-1 text-sm text-gray-600 mb-2">
                    Atualize os dados de um usuário.
                </p>
                <form onSubmit={handleSubmit(onSubmit)} className="grid lg:grid-cols-2 gap-2">
                    <div className="col-span-2">
                        <FormInput
                            className="col-span-2"
                            label="Nome completo"
                            placeholder="Digite aqui..."
                            name="name"
                            register={register}
                            error={errors?.name}
                            required
                        />
                    </div>
                    <FormInput
                        label="Endereço de e-mail"
                        placeholder="exemplo@exemplo.com"
                        name="email"
                        register={register}
                        error={errors?.email}
                        required
                    />
                    <FormInput
                        label="Senha"
                        placeholder="*******"
                        name="password"
                        type="password"
                        register={register}
                        error={errors?.email}
                        required
                    />
                    <FormSelect
                        label="Concessionária"
                        name="dealership_group_id"
                        register={register}
                        error={errors?.dealership_group_id}
                        required
                    />
                    <FormSelect
                        label="Concessionária filial (opcional)"
                        name="dealership_branch_id"
                        register={register}
                        error={errors?.dealership_branch_id} />
                    <div className="col-span-2 w-full flex items-end justify-end">
                        <Button loading={isLoading}>Salvar</Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ModalUpdate;