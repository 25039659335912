import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import FormSelect from "../../../../components/forms/formSelect/FormSelect";
import FormInput from "../../../../components/forms/formInput/FormInput";
import FormRadioButton from "../../../../components/forms/formRadioButton/FormRadioButton";
import RadioGroup from "../../../../components/forms/formRadioButton/RadioGroup";

import { yupResolver } from "@hookform/resolvers/yup";
import schema from "../../../../extras/schemas/essentialInformation.schema";
import { useOutletContext } from "react-router-dom";
import { useCategory, useManufacturer, useModel, useSector, useUpdateProduct } from "../../../../extras/services/queries";
import { toast } from "react-toastify";
import { CurrencyInput } from "react-currency-mask";

const Edit = (props) => {
  const [product, setProduct] = useOutletContext();
  const INPUTS_TO_CHANGE = ['sector_id', 'name', 'serial_number', 'year', 'horse_power', 'engine_hours', 'price', 'preservation', 'description'];
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sector_id: null,
      category_id: null,
      manufacturer_id: null,
      model_id: null
    }
  });

  const { data: sector, isPending: isLoadingSector } = useSector();
  const { mutate: mutateCategory, isPending: isLoadingCategory } = useCategory();
  const { mutate: mutateManufacturer, isPending: isLoadingManufacturer } = useManufacturer();
  const { mutate: mutateModel, isPending: isLoadingModel } = useModel();
  const { mutate, isPending } = useUpdateProduct();

  const [category, setCategory] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [model, setModel] = useState([]);

  const years = [];

  for (let i = 1900; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }

  const onSubmit = (data) => {
    mutate({ ...data, id: product?.id }, {
      onSuccess: (res) => {
        if (res?.status == 200)
          toast.success("Informações atualizada com sucesso!");
      }
    })
  }

  useEffect(() => {
    if (!watch("sector_id"))
      return;

    const watchCurrent = watch("category_id");

    setCategory([]);
    setManufacturer([]);
    setModel([]);

    if (watchCurrent !== null) {
      setValue("category_id", "");
      setValue("manufacturer_id", "");
      setValue("model_id", "");
    }

    mutateCategory(watch("sector_id"), {
      onSuccess: (res) => {
        setCategory(res?.data)
        if (watchCurrent === null)
          setValue("category_id", product?.category_id)

      }
    })
  }, [watch("sector_id")])

  useEffect(() => {
    if (!watch("category_id") || watch("category_id") <= 0)
      return;

    const watchCurrent = watch("manufacturer_id");

    setValue("category_id", watch("category_id"));
    if (watchCurrent !== null) {
      setValue("manufacturer_id", "");
      setValue("model_id", "");
    }

    mutateManufacturer(watch("category_id"), {
      onSuccess: (res) => {
        setManufacturer(res?.data);

        if (watchCurrent === null)
          setValue("manufacturer_id", product?.manufacturer_id)
      }
    })
  }, [watch("category_id")])

  useEffect(() => {
    if (!watch("manufacturer_id") || watch("manufacturer_id") <= 0)
      return;
    const watchCurrent = watch("model_id");

    if (watchCurrent !== null)
      setValue("model_id", "");

    mutateModel(watch("manufacturer_id"), {
      onSuccess: (res) => {
        setModel(res?.data)
        if (watchCurrent === null)
          setValue("model_id", product?.model_id)
      }
    })
  }, [watch("manufacturer_id")])

  useEffect(() => {
    if (!isLoadingSector && product)
      setValue("sector", product?.sector_id)

  }, [isLoadingSector, JSON.stringify(product)])

  useEffect(() => {
    if (product)
      Object.keys(product).forEach(chave => {
        if (INPUTS_TO_CHANGE.includes(chave)) {
          const value = chave;

          if (chave == 'sector_id')
            value.replace('_id', '');

          setValue(value, product[chave]);
        }
      });
  }, [JSON.stringify(product)])

  useEffect(() => {
    console.log(errors)
  }, [errors])
  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <div className="grid grid-cols-1 md:grid-cols-2  md:gap-5">

          <FormSelect
            label="Setor"
            options={sector?.data?.map(item => ({
              label: item.name,
              value: item.id
            }))}
            name="sector"
            register={register}
            isLoading={isLoadingSector}
            error={errors?.sector_id}
          />
          <FormSelect
            label="Categoria"
            options={category?.map(item => ({
              label: item.name,
              value: item.id
            }))}
            name="category_id"
            register={register}
            error={errors?.category_id}
            isLoading={isLoadingCategory}
          />
          <FormSelect
            label="Fabricante"
            options={manufacturer?.map(item => ({
              label: item.name,
              value: item.id
            }))}
            name="manufacturer_id"
            register={register}
            error={errors?.manufacturer_id}
            isLoading={isLoadingManufacturer}
          />
          <FormSelect
            label="Modelo"
            options={model?.map(item => ({
              label: item.name,
              value: item.id
            }))}
            name="model_id"
            register={register}
            error={errors?.model_id}
            isLoading={isLoadingModel}
          />
          <div className="col-span-2">
            <FormInput
              label="Título"
              placeholder="Título"
              name="name"
              register={register}
              error={errors?.name}
            />
          </div>
          <FormInput
            label="Número de série"
            placeholder="Insira o número de série"
            name="serial_number"
            register={register}
            error={errors?.serial_number}
          />
          <FormSelect
            label="Ano"
            options={years?.map(item => {
              return ({
                label: item.toString(),
                value: item
              })
            })}
            name="year"
            register={register}
            error={errors?.year}
          />
          <FormInput
            label="Potência"
            placeholder="Insira a potência"
            name="horse_power"
            register={register}
            error={errors?.horse_power}
          />
          <FormInput
            label="Horas trabalhadas"
            placeholder="Insira o número de horas trabalhadas"
            name="engine_hours"
            register={register}
            error={errors?.engine_hours}
          />
          <label className="mb-3 block">
            <span className="font-bold text-slate-700 mb-1 inline-block text-sm ml-1 dark:text-white">
              Preço
            </span>
            <CurrencyInput
              onChangeValue={(event, originalValue, maskedValue) => {
                setValue('price', originalValue);
              }}
              value={getValues('price')}
              InputElement={<input
                type="text"
                label="price"
                className="py-3 px-4 dark:text-white text-slate-700 dark:bg-transparent placeholder:text-slate-300 dark:border-slate-800 outline-none border border-slate-100 rounded-lg w-full focus:border-[#06B0B4] dark:focus:border-[#06B0B4] focus:shadow-[0px_0px_0px_5px_rgba(6,_176,_180,_0.1)]"
                placeholder="Insira o preço"
                name="price"
              />}
            />
            {errors?.price?.message && (
              <span className="text-red-500 text-sm">{errors?.price?.message}</span>
            )}
          </label>
          <RadioGroup
            label="Estado de conservação"
            error={errors?.preservation}
          >
            <FormRadioButton
              label="Ótimo"
              value="Ótimo"
              name="preservation"
              register={register}
            />
            <FormRadioButton
              label="Bom"
              value="Bom"
              name="preservation"
              register={register}
            />
            <FormRadioButton
              label="Regular"
              value="Regular"
              name="preservation"
              register={register}
            />
            <FormRadioButton
              label="Ruim"
              value="Ruim"
              name="preservation"
              register={register}
            />
          </RadioGroup>
        </div>
        <div>
          <FormInput
            label="Descrição"
            placeholder="Insira a descrição"
            name="description"
            register={register}
            error={errors?.description}
          />

        </div>

        <div className="flex flex-row items-start mt-2">
          <button
            type="submit"
            className="font-medium bg-[#002772] text-white px-7 py-2 rounded-full hover:opacity-80"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default Edit;
