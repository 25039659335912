import React from "react";

import Header from "./Header";
import { Files } from "lucide-react";
import { Content } from "../../../components/layout";
import Menu from "./Menu";
import { Outlet } from "react-router-dom";

const Documents = () => {
  return (
    <Content title="Documentos" icon={<Files />}>
      <Header />

      <div className="px-5 mb-5">
        <div className="bg-white dark:bg-slate-900 shadow-sm rounded-md px-5 py-4">
          <Menu />

          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Documents;
