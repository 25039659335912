import React from "react";

import { NavLink, useLocation, useParams } from "react-router-dom";

const Menu = () => {
  const params = useParams();
  const location = useLocation();

  const menuItems = [
    {
      title: "Informações essenciais",
      linkTo: `/estoque/${params.id}/editar/informacoes-essenciais`,
    },
    // {
    //   title: "Descrição",
    //   linkTo: `/estoque/${params.id}/editar/descricao`,
    // },
    {
      title: "Local do equipamento",
      linkTo: `/estoque/${params.id}/editar/local-do-equipamento`,
    },
    {
      title: "Fotos",
      linkTo: `/estoque/${params.id}/editar/fotos`,
    },
    {
      title: "Atributos",
      linkTo: `/estoque/${params.id}/editar/atributos`,
    },
  ];

  const isLinkActive = (linkTo) =>
    location.pathname === linkTo
      ? "text-[#002772] dark:text-white font-medium text-sm border-b-[3px] border-[#002772] dark:border-[#06B0B4] pb-3 whitespace-nowrap"
      : "text-slate-400 dark:text-slate-600 font-medium text-sm border-b-[3px] border-transparent pb-3 hover:text-[#002772] dark:hover:text-white hover:border-[#002772] dark:hover:border-[#06B0B4] whitespace-nowrap";

  return (
    <ul className="flex flex-row gap-8 overflow-x-auto pb-5">
      {menuItems.map((item, index) => {
        return (
          <li key={index}>
            <NavLink to={item.linkTo} className={isLinkActive(item.linkTo)}>
              {item.title}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export default Menu;
