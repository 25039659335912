import React, { useEffect, useState } from "react";
import { useListUsers } from "../../../../extras/services/queries";
import ColumnTable from "../../../../components/tables/ColumnTable";
import { createColumnHelper } from "@tanstack/react-table";
import { Edit, Trash2 } from "lucide-react";
import Avatar from "../../../../extras/imgs/images.png";
import ModalCreate from "./deps/ModalCreate";
import ModalUpdate from "./deps/ModalUpdate";

const Accounts = () => {
  const [showUpdate, setShowUpdate] = useState(false);
  const [userId, setUserId] = useState(null);

  const columnHelper = createColumnHelper();

  const editUser = (user_id) => {
    setUserId(user_id);
    setShowUpdate(true);
  }

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => <div>Nome</div>,
      cell: (info) => (
        <td className="px-2 py-3 text-slate-400 text-sm flex flex-row gap-5 items-center">
          <img class="w-10 h-10 rounded-full" src={Avatar} alt="Rounded avatar" />

          <span>
            {info.getValue()}
          </span>
        </td>

      ),
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => null,
      cell: (info) => (
        <td className="px-7 py-3 text-right">
          <div className="flex flex-row items-end justify-end gap-5">
            <button onClick={() => editUser(info.getValue())} className="bg-[#E3E7EF] rounded-sm p-2 text-[#002772] hover:opacity-75">
              <Edit />
            </button>
            <button className="bg-[#E3E7EF] rounded-sm p-2 text-[#002772] hover:opacity-75">
              <Trash2 />
            </button>
          </div>
        </td>
      ),
    })]
  return (
    <>
      <ColumnTable
        title="Contas"
        route={useListUsers}
        columnsData={columns}
        ModalCreate={ModalCreate}
      />
      <ModalUpdate show={showUpdate} setShow={setShowUpdate} userId={userId}/>
    </>
  );
};

export default Accounts;
