import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import FormSelect from "../../../../components/forms/formSelect/FormSelect";
import FormInput from "../../../../components/forms/formInput/FormInput";
import FormRadioButton from "../../../../components/forms/formRadioButton/FormRadioButton";
import RadioGroup from "../../../../components/forms/formRadioButton/RadioGroup";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "../../../../extras/schemas/essentialInformation.schema";
import { useCategory, useCategoryAttributes, useManufacturer, useModel, useSector } from "../../../../extras/services/queries";
import "./deps/style.css";
import { toast } from "react-toastify";
import Attributes from "../../../../components/product/attributes/Attributes";
import { CurrencyInput } from "react-currency-mask";
import { getCookie } from "react-use-cookie";

const StepStepEssential = ({ data, setData, images, mainPicture }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [category, setCategory] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [model, setModel] = useState([]);

  const { data: sector, isPending: isLoadingSector } = useSector();
  const { mutate: mutateCategory, isPending: isLoadingCategory } = useCategory();
  const { mutate: mutateManufacturer, isPending: isLoadingManufacturer } = useManufacturer();
  const { mutate: mutateModel, isPending: isLoadingModel } = useModel();
  const { mutate: mutateCategoryAttributes, isPending: isPendingCategoryAttributes } = useCategoryAttributes();

  const onSubmit = async (form) => {
    const formData = new FormData();
    const allData = { ...form, ...data };

    Object.keys(allData).forEach(key => {
      if (data[key] != "")
        formData.append(key, allData[key]);
    });

    images?.forEach(async (file, index) => {
      formData.append("images[" + index + "][image]", file);
      if (mainPicture === index)
        formData.append("images[" + index + "][main]", true);
    });

    attributes?.forEach(async (item, index) => {
      formData.append("attributes[" + index + "][score]", item.score ?? 0);
      formData.append("attributes[" + index + "][attribute_id]", item.id);
    });


    let bearerToken = "";

    try {
      bearerToken = getCookie("authorization");
    } catch (e) {
      new Error(e);
    }

    await fetch(`${process.env.REACT_APP_BASE_API_URL}/product/create`,
      {
        method: "POST",
        headers: {
          contentType: false,
          accept: 'application/json',
          mimeType: "multipart/form-data",
          Authorization: "Bearer " + bearerToken,
          "Token-Access": process.env.REACT_APP_BASE_API_KEY,
        },
        body: formData,
      }
    ).then((data) => {
      if (data.status == 200) {
        reset();
        toast.success("Produto adicionado com sucesso!");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 5000);
      } else {
        if (data.status == 403) {
          toast.error(`Erro devido a arquivos excessivamente grandes ou muitos por vez!`);
          return;
        }

        toast.error(`Erro ${data.status}: ${data.statusText}`);
      }
    });
  };

  useEffect(() => {
    if (!watch("sector"))
      return;

    setCategory([]);
    setManufacturer([]);
    setModel([]);
    setAttributes([]);

    setValue("category", "");
    setValue("manufacturer", "");
    setValue("model", "");

    mutateCategory(watch("sector"), {
      onSuccess: (res) => {
        setCategory(res?.data)
      }
    })
  }, [watch("sector")])

  useEffect(() => {
    if (!watch("category") || watch("category") <= 0)
      return;

    setValue("category", watch("category"));

    setValue("manufacturer", "");
    setValue("model", "");
    setAttributes([]);

    mutateCategoryAttributes(watch("category"), {
      onSuccess: (res) => {
        setAttributes(res?.data)
      }
    })

    mutateManufacturer(watch("category"), {
      onSuccess: (res) => {
        setManufacturer(res?.data)
      }
    })
  }, [watch("category")])

  useEffect(() => {
    if (!watch("manufacturer") || watch("manufacturer") <= 0)
      return;

    setValue("model", "");

    mutateModel(watch("manufacturer"), {
      onSuccess: (res) => {
        setModel(res?.data)
      }
    })
  }, [watch("manufacturer")])

  useEffect(() => {
    if (!watch("model") || watch("model") <= 0)
      return;

    const categoryName = category?.filter(item => item.id == watch("category"))?.map(item => (item.name)).join(', ');
    const manufacturerName = manufacturer?.filter(item => item.id == watch("manufacturer"))?.map(item => (item.name)).join(', ');
    const modelName = model?.filter(item => item.id == watch("model"))?.map(item => (item.name)).join(', ');

    setValue("name", `${categoryName} ${manufacturerName} ${modelName}`);
  }, [watch("model")])

  const years = [];

  for (let i = 1900; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }


  const changeScore = (index, novoScore) => {
    setAttributes(prevState => {
      const newArray = [...prevState];

      if (index >= 0 && index < newArray.length)
        newArray[index].score = novoScore;
      return newArray;
    });
  }

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <div className="grid grid-cols-1 md:grid-cols-2  md:gap-5">

          <FormSelect
            label="Setor"
            options={sector?.data?.map(item => ({
              label: item.name,
              value: item.id
            }))}
            name="sector"
            register={register}
            isLoading={isLoadingSector}
            error={errors?.sector}
          />
          <FormSelect
            label="Categoria"
            options={category?.map(item => ({
              label: item.name,
              value: item.id
            }))}
            name="category"
            register={register}
            error={errors?.category}
            isLoading={isLoadingCategory}
          />
          <FormSelect
            label="Fabricante"
            options={manufacturer?.map(item => ({
              label: item.name,
              value: item.id
            }))}
            name="manufacturer"
            register={register}
            error={errors?.manufacturer}
            isLoading={isLoadingManufacturer}
          />
          <FormSelect
            label="Modelo"
            options={model?.map(item => ({
              label: item.name,
              value: item.id
            }))}
            name="model"
            register={register}
            error={errors?.model}
            isLoading={isLoadingModel}
          />
          <div className="col-span-2">
            <FormInput
              label="Título"
              placeholder="Título"
              name="name"
              register={register}
              error={errors?.name}
            />
          </div>
          <FormInput
            label="Número de série"
            placeholder="Insira o número de série"
            name="serial_number"
            register={register}
            error={errors?.serial_number}
          />
          <FormSelect
            label="Ano"
            options={years?.map(item => {
              return ({
                label: item.toString(),
                value: item
              })
            })}
            name="year"
            register={register}
            error={errors?.year}
          />
          <FormInput
            label="Potência"
            placeholder="Insira a potência"
            name="horse_power"
            register={register}
            error={errors?.horse_power}
          />
          <FormInput
            label="Horas trabalhadas"
            placeholder="Insira o número de horas trabalhadas"
            name="engine_hours"
            register={register}
            error={errors?.engine_hours}
          />
          <label className="mb-3 block">
            <span className="font-bold text-slate-700 mb-1 inline-block text-sm ml-1 dark:text-white">
              Preço
            </span>
            <CurrencyInput
              onChangeValue={(event, originalValue, maskedValue) => {
                setValue('price', originalValue);
              }}
              value={getValues('price')}
              InputElement={<input
                type="text"
                label="price"
                className="py-3 px-4 dark:text-white text-slate-700 dark:bg-transparent placeholder:text-slate-300 dark:border-slate-800 outline-none border border-slate-100 rounded-lg w-full focus:border-[#06B0B4] dark:focus:border-[#06B0B4] focus:shadow-[0px_0px_0px_5px_rgba(6,_176,_180,_0.1)]"
                placeholder="Insira o preço"
                name="price"
              />}
            />
            {errors?.price?.message && (
              <span className="text-red-500 text-sm">{errors?.price?.message}</span>
            )}
          </label>
          <RadioGroup
            label="Estado de conservação"
            error={errors?.preservation}
          >
            <FormRadioButton
              label="Ótimo"
              value="Ótimo"
              name="preservation"
              register={register}
            />
            <FormRadioButton
              label="Bom"
              value="Bom"
              name="preservation"
              register={register}
            />
            <FormRadioButton
              label="Regular"
              value="Regular"
              name="preservation"
              register={register}
            />
            <FormRadioButton
              label="Ruim"
              value="Ruim"
              name="preservation"
              register={register}
            />
          </RadioGroup>
        </div>
        <div>
          <FormInput
            label="Descrição"
            placeholder="Insira a descrição"
            name="description"
            register={register}
            error={errors?.description}
          />
        </div>
        <div className="p-2 border rounded">
          <div className="p-4 font-bold">Atributos ({attributes.length})</div>
          <div className="px-4">
            <Attributes attributes={attributes} onChange={changeScore} />
          </div>
        </div>
        <div className="flex flex-row items-start mt-2">
          <button
            type="submit"
            className="font-medium bg-[#002772] text-white px-7 py-2 rounded-full hover:opacity-80 mb-2"
          >
            Cadastrar
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepStepEssential;
