import React from "react";

import { SideMenu } from "../";
import { Outlet } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LayoutBase = () => {
  return (
    <div className="bg-blue-50/50 dark:bg-slate-950 w-full h-screen flex flex-row">
      {/* Navegação lateral */}
      <SideMenu />
      <ToastContainer />
      <Outlet />
    </div>
  );
};

export default LayoutBase;
