import React from "react";

import { AlertOctagon } from "lucide-react";

const TitleHeader = ({ title, icon, children }) => {
  return (
    <div className="w-full">
      <div className="flex flex-row items-center gap-5 mb-5">
        {icon}
        <h2 className="text-[#002772] dark:text-white text-xl">{title}</h2>
      </div>
      <div>
        <div className="w-full">{children}</div>
      </div>
    </div>
  );
};

TitleHeader.defaultProps = {
  title: "Title",
  icon: <AlertOctagon />,
  children: <div />,
};

export default TitleHeader;
