import React from "react";

const FormTextArea = ({ label, name, placeholder, register, error }) => {
  const classes = error
    ? "py-3 px-4 dark:text-white text-slate-700 dark:bg-transparent placeholder:text-slate-300 dark:border-slate-800 outline-none border border-slate-100 rounded-lg w-full border-red-500 dark:focus:border-red-500 focus:shadow-[0px_0px_0px_5px_#fed7d7]"
    : "py-3 px-4 dark:text-white text-slate-700 dark:bg-transparent placeholder:text-slate-300 dark:border-slate-800 outline-none border border-slate-100 rounded-lg w-full focus:border-[#06B0B4] dark:focus:border-[#06B0B4] focus:shadow-[0px_0px_0px_5px_rgba(6,_176,_180,_0.1)]";

  return (
    <label className="mb-3 block">
      <span className="font-normal text-slate-700 mb-1 inline-block text-sm ml-1 dark:text-white">
        {label}
      </span>
      <textarea
        name={name}
        {...register(name)}
        placeholder={placeholder}
        className={classes}
      />
      {error?.message && (
        <span className="text-red-500 text-sm">{error.message}</span>
      )}
    </label>
  );
};

export default FormTextArea;
