import React, { useEffect, useState } from "react";

import Logo from "../../../components/logo/Logo";
import { useForm } from "react-hook-form";
import FormInput from "../../../components/forms/formInput/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "../../../extras/schemas/login.schema";
import { useLogin } from "../../../extras/services/queries";
import { toast } from "react-toastify";
import { setCookie } from "react-use-cookie";
import { useNavigate } from "react-router-dom";

const Signin = () => {
  const navigate = useNavigate();
  const { mutate, isPending, data, error } = useLogin();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    mutate(data, {
      onSuccess: (res) => {
        if (res?.status === 200) {
          setCookie("authorization", res?.data?.token, {
            days: 2
          });
          navigate('/dashboard');
        }
      },
      onError: (res) => {
        toast.error('E-mail ou senha incorretos!');
      },
    });
  }

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-blue-50/50">
      <Logo />

      <div className="w-full px-5 my-8 flex flex-row items-center justify-center">
        <div className="bg-white dark:bg-slate-900 shadow-sm rounded-md p-5 pb-6 w-full max-w-[380px]">
          <form onSubmit={handleSubmit(onSubmit)}>

            <FormInput
              label="Endereço de e-mail"
              placeholder="exemplo@exemplo.com"
              name="email"
              register={register}
              error={errors?.email}
            />
            <FormInput
              type="password"
              label="Senha de acesso"
              placeholder="Senha de acesso"
              name="password"
              register={register}
              error={errors?.password}
            />

            <button
              type="submit"
              className="font-medium bg-[#002772] text-white px-7 py-2 rounded-full hover:opacity-80 w-full mt-2"
            >
              Entrar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signin;
