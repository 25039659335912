import { Download } from "lucide-react";
import React from "react";

const ReportsItem = ({ title, action }) => {
  return (
    <li className="w-full">
      <button
        className="flex flex-col lg:flex-row items-center gap-5 justify-center w-full py-10 rounded-md bg-white shadow-md hover:opacity-75"
        onClick={action}
      >
        <span className="text-[#06B0B4]">
          <Download />
        </span>
        <span className="text-[#002772] text-sm font-semibold">{title}</span>
      </button>
    </li>
  );
};

export default ReportsItem;
