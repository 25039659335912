import React, { useEffect, useState } from "react";

import { PlusSquare } from "lucide-react";
import { Content } from "../../../components/layout";

// Steps
import StepItem from "./steps/StepItem";
import StepPictures from "./steps/StepPictures";
import StepLocalization from "./steps/StepLocalization";
import StepEssential from "./steps/StepEssential";

const NewEquipment = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [images, setImages] = useState([]);
  const [mainPicture, setMainPicture] = useState(-1);
  const [data, setData] = useState({});
  const [steps] = useState([
    "Inserir Imagens",
    "Localização",
    "Informações Essenciais",
  ]);

  useEffect(() => {
    console.log(data);
  }, [JSON.stringify(data)]);

  return (
    <Content title="Cadastrar equipamento" icon={<PlusSquare />}>
      <div className="px-5 mt-5">
        <div className="bg-white dark:bg-slate-900 shadow-sm rounded-md py-3 px-5">
          {/* Step information */}
          <div className="flex flex-row justify-around lg:justify-normal gap-5 lg:gap-8 mt-2">
            {steps.map((step, index) => {
              return (
                <StepItem
                  key={index}
                  currentStep={currentStep}
                  step={step}
                  index={index}
                />
              );
            })}
          </div>

          {/* Content */}
          {currentStep === 0 && (
            <StepPictures
              i={0}
              currentStep={currentStep}
              mainPicture={mainPicture}
              setMainPicture={setMainPicture}
              setCurrentStep={setCurrentStep}
              setImages={setImages}
            />
          )}

          {currentStep === 1 && (
            <StepLocalization
              i={1}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              setData={setData}
            />
          )}

          {currentStep === 2 && (
            <StepEssential
              i={2}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              data={data}
              images={images}
              mainPicture={mainPicture}
            />
          )}
          {/* End content */}
        </div>
      </div>
    </Content>
  );
};

export default NewEquipment;
