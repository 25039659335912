import { useState } from "react";
import Modal from "../../../../../components/modal/Modal";
import FormInput from "../../../../../components/forms/formInput/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import createUserSchema from "../../../../../extras/schemas/create-user.schema";
import FormSelect from "../../../../../components/forms/formSelect/FormSelect";
import Button from "../../../../../components/button/Button";
import { useCreateUser } from "../../../../../extras/services/queries";
import { X } from "lucide-react";

const ModalCreate = (props) => {
    const { show, setShow } = props;
    const { mutate, isLoading } = useCreateUser();
    const closeModal = () => {
        setShow(false);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(createUserSchema),
    });

    const onSubmit = (data) => {
        //TODO: Imprimir erro/sucesso

        mutate(data, {
            onSuccess: (res) => {
                console.log(res);
            },
            onError: (res) => {
                console.log(res)
            }
        })
    }

    return (
        <Modal show={show} onClose={closeModal}>
            <div className="p-3">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-medium text-gray-900 dark:text-white ">
                        Criar usuário
                    </h2>
                    <div onClick={() => closeModal()} className="cursor-pointer">
                        <X />
                    </div>
                </div>
                <p className="mt-1 text-sm text-gray-600 mb-2">
                    Preencha os campos obrigatórios para criar um usuário.
                </p>
                <form onSubmit={handleSubmit(onSubmit)} className="grid lg:grid-cols-2 gap-2">
                    <div className="col-span-2">
                        <FormInput
                            className="col-span-2"
                            label="Nome completo"
                            placeholder="Digite aqui..."
                            name="name"
                            register={register}
                            error={errors?.name}
                            required
                        />
                    </div>
                    <FormInput
                        label="Endereço de e-mail"
                        placeholder="exemplo@exemplo.com"
                        name="email"
                        register={register}
                        error={errors?.email}
                        required
                    />
                    <FormInput
                        label="Senha"
                        placeholder="*******"
                        name="password"
                        type="password"
                        register={register}
                        error={errors?.email}
                        required
                    />
                    <FormSelect
                        label="Concessionária"
                        name="dealership_group_id"
                        register={register}
                        error={errors?.dealership_group_id}
                        required
                    />
                    <FormSelect
                        label="Concessionária filial (opcional)"
                        name="dealership_branch_id"
                        register={register}
                        error={errors?.dealership_branch_id} />
                    <div className="col-span-2 w-full flex items-end justify-end">
                        <Button loading={isLoading}>Criar</Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ModalCreate;