import React from "react";

import { Check, Clock9, X } from "lucide-react";

const variants = {
  green: {
    iconColor: "text-[#64D778]",
    bg: "bg-green-500/5",
    label: "text-[#64D778]",
    icon: <Check size={34} />,
  },
  yellow: {
    iconColor: "text-yellow-400",
    bg: "bg-yellow-500/5",
    label: "text-yellow-400",
    icon: <Clock9 size={34} />,
  },
  purple: {
    iconColor: "text-purple-400",
    bg: "bg-purple-500/5",
    label: "text-purple-400",
    icon: <X size={34} />,
  },
  red: {
    iconColor: "text-red-400",
    bg: "bg-red-500/5",
    label: "text-red-400",
    icon: <X size={34} />,
  },
};

const CardBlockItemVertical = ({ value, label, variant }) => {
  return (
    <div className="bg-white dark:bg-slate-900 shadow-sm rounded-md py-6 px-9 flex flex-row items-center">
      <div
        className={`w-20 h-20 flex flex-row items-center justify-center rounded-full ${variants[variant].bg} ${variants[variant].iconColor} shrink-0 mr-10`}
      >
        {variants[variant].icon}
      </div>

      <div className="flex flex-col mr-7">
        <h2 className="text-[#2D374B] dark:text-white text-4xl font-bold mb-1">
          {value}
        </h2>
        <div className="flex flex-col">
          <span className={`font-medium text-sm ${variants[variant].label}`}>
            {label}
          </span>
          <span className="text-[#828B9D]">Mensal</span>
        </div>
      </div>
    </div>
  );
};

export default CardBlockItemVertical;
