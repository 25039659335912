import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import Attributes from "../../../../components/product/attributes/Attributes";
import { toast } from "react-toastify";
import { useInfoProduct } from "../../../../extras/services/queries";
import { getCookie } from "react-use-cookie";

const EditAtributtes = () => {
  const params = useParams();
  const { mutate } = useInfoProduct();

  const [attributes, setAttributes] = useState([]);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    mutate({ id: params?.id }, {
      onSuccess: (res) => {
        setProduct(res?.data)
      }
    })
  }, [params?.id])

  const changeScore = (index, novoScore) => {
    setAttributes(prevState => {
      const newArray = [...prevState];

      if (index >= 0 && index < newArray.length)
        newArray[index].score = novoScore;
      return newArray;
    });
  }

  useEffect(() => {
    if (product?.attributes) {
      const newAttributes = product?.attributes.map((item) => {
        return (
          {
            name: item?.categorie_attribute?.name,
            max_score: item?.categorie_attribute?.max_score,
            attribute_id: item.attribute_id,
            score: item.score
          }
        )
      })

      setAttributes(newAttributes);
    }
  }, [JSON.stringify(product)]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    attributes?.forEach(async (item, index) => {
      formData.append("attributes[" + index + "][score]", item.score ?? 0);
      formData.append("attributes[" + index + "][attribute_id]", item.attribute_id);
    });

    let bearerToken = "";

    try {
      bearerToken = getCookie("authorization");
    } catch (e) {
      new Error(e);
    }

    await fetch(`${process.env.REACT_APP_BASE_API_URL}/product/${product?.id}/update`,
      {
        method: "POST",
        headers: {
          contentType: false,
          accept: 'application/json',
          mimeType: "multipart/form-data",
          Authorization: "Bearer " + bearerToken,
          "Token-Access": process.env.REACT_APP_BASE_API_KEY,
        },
        body: formData,
      }
    ).then((data) => {
      if (data.status == 200) {
        toast.success("Atributos salvos com sucesso!");
      } else {
        if (data.status == 403) {
          toast.error(`Erro devido a arquivos excessivamente grandes ou muitos por vez!`);
          return;
        }

        toast.error(`Erro ${data.status}: ${data.statusText}`);
      }
    });
  };

  return (
    <div className="overflow-x-auto">
      <form onSubmit={onSubmit}>
        <Attributes attributes={attributes} onChange={changeScore} editMode />
        <button
          type="submit"
          className="font-medium bg-[#002772] text-white px-7 py-2 rounded-full hover:opacity-80 mt-5"
        >
          Salvar
        </button>
      </form>
    </div>
  );
};

export default EditAtributtes;
