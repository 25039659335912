import React from "react";

import { ArrowRight } from "lucide-react";

const LotsItem = ({ item }) => {
  return (
    <div className="hover:shadow-sm hover:bg-gray-50 dark:hover:bg-slate-800 flex flex-row gap-5 p-2 rounded-xl cursor-pointer">
      <div>
        <div className="w-14 h-14 rounded-xl overflow-hidden relative">
          <img
            className="mx-auto w-full h-full object-cover"
            src={item?.main_picture?.path}
            alt="Imagem de perfil"
          />
        </div>
      </div>

      <div className="w-full ">
        <span className="align-middle line-clamp-2">
          {item.name}
        </span>
      </div>

      <div className="flex flex-row items-center justify-center pr-2">
        <ArrowRight className="text-[#64D778]" />
      </div>
    </div>
  );
};

export default LotsItem;
