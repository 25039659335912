import { Edit, Plus, Trash2 } from "lucide-react";
import Button from "../button/Button";
import { useEffect, useMemo, useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { Pagination } from "./components/Pagination";
import Spinner from "../layout/utils/Spinner";

export default function ColumnTable(props) {
    const { columnsData, title, route, create, styleType = "default", ModalCreate, ListMenu, visiblePageCount, routeParam, Filter, customParams, classContainer, exportRoute, specialRoute } = props;
    const { mutate, data: dataMutate, isPending: isLoading } = route(routeParam);

    const [sorting, setSorting] = useState([]);
    const [perPage, setPerPage] = useState(4);
    const [page, setPage] = useState(0);
    const [showCreate, setShowCreate] = useState(false);
    const columns = useMemo(() => columnsData, [columnsData]);
    const [data, setData] = useState([]);
    const pageCount = !isLoading ? dataMutate?.data?.last_page : 10;

    const titleClass = {
        default: "text-[#002772] text-sm font-semibold text-left px-7 flex gap-1",
        borded: "mb-4 text-slate-500 dark:text-white flex gap-1"
    };

    const tableClass = {
        default: "w-full shadow-md rounded-md",
        borded: "w-full shadow-sm rounded-lg overflow-hidden"
    };

    const theadClass = {
        default: "",
        borded: "border-l-[8px] border-b border-slate-50 dark:border-b-slate-800"
    };

    const trClass = {
        default: "shadow-sm",
        borded: ""
    };

    const thClass = {
        default: "text-slate-400 text-sm font-semibold text-left px-7 py-4",
        borded: ""
    };

    const trRowClass = {
        default: "border-b",
        borded: "border-l-[8px] border-b-[1px] border-b-slate-50 dark:border-b-slate-800 hover:dark:bg-slate-800"
    };

    const tdRowClass = {
        default: "px-7 py-3 text-slate-400 text-sm",
        borded: ""
    };

    const table = useReactTable({
        data: data ? data : [],
        columns: columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableHiding: true,
        meta: {
            updateData: (rowIndex, columnId, value) => {
                // Skip page index reset until after next rerender
                //skipAutoResetPageIndex()
                setData(old =>
                    old.map((row, index) => {
                        if (index === rowIndex) {
                            return {
                                ...old[rowIndex],
                                [columnId]: value,
                            }
                        }
                        return row
                    })
                )
            },
        },
        debugTable: false,
    });

    useEffect(() => {
        setData([]);
        let params = { page: page + 1, per_page: perPage };

        if (customParams)
            params = { ...params, ...customParams };

        mutate(params, {
            onSuccess: (res) => {
                setData(res.data.data);
            }
        });

    }, [page, perPage, JSON.stringify(customParams)]);

    return (
        <div className="">
            {ModalCreate && <ModalCreate show={showCreate} setShow={setShowCreate} />}
            <div className="flex flex-row justify-between items-center py-3">
                <h1 className={`${titleClass[styleType]}`}>
                    {title} <div className="flex items-center gap-[1px]">({isLoading ? <Spinner /> : dataMutate?.data?.total})</div>
                </h1>
                {ModalCreate &&
                    <Button onClick={() => setShowCreate(true)}>
                        <div className="flex flex-row items-center gap-2">
                            <Plus size={16} />
                            Adicionar
                        </div>
                    </Button>
                }
            </div>

            <table className={`${tableClass[styleType]}`}>
                <thead className={`${theadClass[styleType]}`}>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr className={`${trClass[styleType]}`} key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th
                                        key={header.id}
                                        className={`${thClass[styleType]}`}
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        {/* Adicione a funcionalidade de seleção de todos os itens aqui */}
                                        {header.id === 'product' ? (
                                            <></>
                                        ) : (
                                            flexRender(
                                                header.column.columnDef
                                                    .header,
                                                header.getContext()
                                            )
                                        )}
                                        {{
                                            asc: "",
                                            desc: "",
                                        }[
                                            header.column.getIsSorted()
                                        ] ?? null}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row, i) => {
                        return (
                            <tr key={"tr" + row.id} className={`border-[${(row.original.colorBorder) ? row.original.colorBorder : '#06B0B4'}] ${trRowClass[styleType]}`}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td
                                            key={"cell" + cell.id}
                                            className={`${tdRowClass[styleType]}`}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {/* <tr className="">
                        <td className="px-7 py-3 text-slate-400 text-sm flex flex-row gap-5 items-center">
                            <img class="w-10 h-10 rounded-full" src={Avatar} alt="Rounded avatar" />

                            <span>
                                Flávia Sobrinho
                            </span>
                        </td>
                        <td className="px-7 py-3 text-slate-400 text-sm">
                            Gerente de vendas
                        </td>
                        <td className="px-7 py-3 text-slate-400 text-sm">
                            <div className="px-5 py-2 shrink-0 inline-flex flex-row items-center justify-center rounded-full bg-green-50 text-green-700 font-semibold">
                                Ativo
                            </div>
                        </td>
                        <td className="px-7 py-3 text-right">
                            <div className="flex flex-row items-end justify-end gap-5">
                                <button className="bg-[#E3E7EF] rounded-sm p-2 text-[#002772] hover:opacity-75">
                                    <Edit />
                                </button>
                                <button className="bg-[#E3E7EF] rounded-sm p-2 text-[#002772] hover:opacity-75">
                                    <Trash2 />
                                </button>
                            </div>
                        </td>
                    </tr> */}
                </tbody>
            </table>

            <div className="pt-3 pb-2">
                <div>
                    <label className="">
                        <span className="pl-7 pr-3 py-3 text-slate-400 text-sm">
                            Itens por página
                        </span>

                        <select className="text-[#002772] font-semibold text-sm pr-10">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                        </select>
                    </label>

                    <span className="pl-7 pr-3 py-3 text-slate-400 text-sm">
                        {!isLoading &&
                            <>
                                {dataMutate?.data?.from}-{dataMutate?.data?.to} de {dataMutate?.data?.total}
                            </>}
                    </span>
                    <div className="flex flex-wrap justify-center gap-3 p-6 py-6">
                        <Pagination
                            visiblePageCount={visiblePageCount}
                            gotoPage={setPage}
                            canPreviousPage={page > 0}
                            canNextPage={page < pageCount}
                            pageCount={pageCount}
                            pageIndex={page}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}