import { Player } from "@lottiefiles/react-lottie-player";
import Logo from "../../logo/Logo";

const LayoutLoading = () => {
    return (
        <div className="bg-blue-50/50 flex items-center justify-center gap-2 h-[100vh] text-lg font-medium">
            <div className="absolute top-8 left-8"><Logo /></div>
            <Player src="https://lottie.host/d4d5a942-01af-4b06-b5a2-2a8cdecf14fe/eFmFwXQI2p.json" loop controls autoplay />
        </div>)
};

export default LayoutLoading;