import React, { useEffect, useState } from "react";

import {
  Edit,
  Gavel,
  XCircle,
  Command,
  Activity,
  ArrowLeft,
  DollarSign,
  CheckCircle,
  CheckSquare,
} from "lucide-react";

import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";

import Content from "../../../components/nestedLayout/content/Content";
import { useInfoProduct } from "../../../extras/services/queries";

const StockDetails = () => {
  const params = useParams();
  const location = useLocation();
  const { mutate, isPending: isLoading } = useInfoProduct();

  const [product, setProduct] = useState(null);

  useEffect(() => {
    mutate({ id: params?.id }, {
      onSuccess: (res) => {
        setProduct(res?.data)
      }
    })
  }, [params?.id])

  const navItems = [
    {
      icon: <Edit />,
      title: "Editar informações",
      linkTo: "/estoque/" + params.id + "/editar/informacoes-essenciais",
      linkGroup: 'editar'
    },
    // {
    //   icon: <DollarSign />,
    //   title: "Ofertas recebidas",
    //   linkTo: "/estoque/" + params.id + "/ofertas-recebidas",
    // },
    // {
    //   icon: <Command />,
    //   title: "Via Consulti",
    //   linkTo: "/estoque/" + params.id + "/viaconsulti",
    // },
    // {
    //   icon: <Activity />,
    //   title: "Estatísticas",
    //   linkTo: "/estoque/" + params.id + "/estatisticas",
    // },
    {
      icon: <CheckSquare />,
      title: "Marcar como vendido",
      linkTo: "/estoque/" + params.id + "/marcar-como-vendido",
      linkGroup: 'marcar-como-vendido'
    },
    {
      icon: <Gavel />,
      title: "Em leilão",
      linkTo: "/estoque/" + params.id + "/em-leilao",
      linkGroup: 'em-leilao'
    },
    // {
    //   icon: <CheckCircle />,
    //   title: "Detalhes da venda",
    //   linkTo: "/estoque/" + params.id + "/detalhes-da-venda",
    // },
    // {
    //   icon: <XCircle />,
    //   title: "Tornar indisponível",
    //   linkTo: "/estoque/" + params.id + "/tornar-indisponivel",
    // },
  ];

  const isActiveIcon = (linkGroup) => {
    const pathArray = location.pathname.split("/")
    return (pathArray.length > 2 && pathArray[3] === linkGroup)
      ? "mb-2 text-cyan-500"
      : "mb-2 group-hover:text-cyan-500";
  }

  const isActiveText = (linkGroup) => {
    const pathArray = location.pathname.split("/")
    return (pathArray.length > 2 && pathArray[3] === linkGroup)
      ? "font-medium text-sm text-[#002772] dark:text-white text-center whitespace-nowrap"
      : "font-medium text-sm group-hover:text-[#002772] dark:group-hover:text-white text-center whitespace-nowrap";
  }
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/estoque/" + params.id) {
      navigate("./editar/informacoes-essenciais");
    }
  }, [location.pathname, navigate, params.id]);

  return (
    <Content
      title={`${product?.name} - ANO ${product?.year}`}
      icon={<ArrowLeft />}
    >
      <div>
        <div className="px-5 py-3 sticky top-0 min-w-full">
          <div className="overflow-x-auto w-full bg-white dark:bg-slate-900 shadow-sm rounded-md py-4">
            <ul className="flex flex-row">
              {navItems.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="px-8 border-r border-slate-50 dark:border-slate-800"
                  >
                    <NavLink
                      to={item.linkTo}
                      className="text-slate-300 dark:text-slate-600 flex flex-col items-center justify-center group shrink-0"
                    >
                      <div className={isActiveIcon(item?.linkGroup)}>
                        {item.icon}
                      </div>
                      <span className={isActiveText(item?.linkGroup)}>
                        {item.title}
                      </span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="pb-5 min-w-full">
          <Outlet context={[product, setProduct]} />
        </div>
      </div>
    </Content>
  );
};

export default StockDetails;
