import React from "react";

import Header from "./Header";
import { UserCog2 } from "lucide-react";
import { Outlet } from "react-router-dom";
import { Content } from "../../../components/layout";
import Menu from "./menu/Menu";

const ManageAccesses = () => {
  return (
    <Content title="Gerenciar acessos" icon={<UserCog2 />}>
      <div className="p-5">
        <Header />
        <div className="bg-white dark:bg-slate-900 shadow-sm rounded-md px-5 py-4">
          <Menu />

          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </Content>
  );
};

export default ManageAccesses;
