import React from "react";

import { Content } from "../../../components/layout";

import { CircleDot } from "lucide-react";

const OpportunityCost = () => {
  return (
    <Content title="Custo de oportunidade" icon={<CircleDot />}>
      <div className="p-5 text-slate-900 dark:text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea atque
        officiis, assumenda, et molestiae aspernatur illo eligendi iusto vitae
        numquam, temporibus omnis? Explicabo, eveniet tempore odio eligendi
        fugit cum error. Expedita laborum minima quos hic iusto enim, sunt quo
        similique obcaecati, molestias accusamus, amet quas corrupti consectetur
        nihil aspernatur quia eum error harum nobis odit. Consectetur voluptate
        inventore ipsa officia. Qui a accusamus magnam, dolorem voluptates in
        fugit, eveniet voluptatem exercitationem earum autem eum ex. Inventore,
        culpa? Distinctio a culpa quaerat ab ut autem consequuntur nesciunt
        atque, at perspiciatis suscipit! Dignissimos velit dolorem ipsum error
        consectetur facere sequi? Nisi voluptas eaque velit ipsa, modi odit
        natus consectetur similique? Architecto dignissimos, laborum delectus
        optio non impedit quis voluptatem vero possimus autem. Omnis, at
        dignissimos sit magni delectus voluptate, tempora culpa eligendi unde
        incidunt, harum rem odio ab iusto. Sit necessitatibus sunt a minus,
        iusto aut dolorem quis quos culpa. Natus, iure! Suscipit optio dolore
        dolor dolores, cum nobis ducimus. Laudantium esse quidem quae quasi
        tempore repudiandae nemo velit nobis, eveniet necessitatibus alias
        accusamus voluptates inventore iure, vitae quas asperiores similique
        beatae? Aut reprehenderit vitae minus sunt officiis ad, totam sit
        magnam, iure nisi laborum voluptates consequatur accusantium deleniti!
        Dolores quos veritatis libero, voluptate est, vel odio inventore
        molestiae at beatae sint! Tempora veritatis ipsum delectus cumque
        tenetur ad maxime nihil voluptatibus dolore consequuntur in adipisci
        nesciunt libero reiciendis voluptate blanditiis architecto quisquam,
        dignissimos doloribus molestiae. Nulla necessitatibus veritatis
        quibusdam dolore sequi? Aperiam nostrum sed aliquid reprehenderit itaque
        vitae illum praesentium ipsa rerum, soluta, expedita sit enim aliquam
        repudiandae veritatis cupiditate nobis. Fugiat perspiciatis officia
        nesciunt delectus. Cum, eum. Sed, earum voluptates! Minus tempora
        quisquam eligendi mollitia, cupiditate commodi aspernatur nihil ratione,
        natus eius nobis excepturi voluptate veniam sit culpa adipisci.
        Recusandae architecto eos quis rerum repellat, maxime itaque eveniet nam
        ullam? Corrupti magni ut possimus, eaque maxime repellendus odit
        consectetur dolores illo nulla vitae facilis alias soluta dolor ipsa,
        quod iste laboriosam, accusantium blanditiis hic veniam omnis magnam
        atque. Reiciendis, minus? Quidem eveniet obcaecati quisquam repellat
        delectus tempore ducimus nemo similique. Eum, labore eius ducimus
        doloremque suscipit nihil facere impedit aspernatur autem excepturi
        fugiat tenetur laudantium ratione explicabo provident soluta.
        Perspiciatis! Autem distinctio totam recusandae quibusdam aut officia
        labore vero ea soluta, aliquam impedit. Quibusdam animi fugit, tenetur
        laudantium amet eos culpa at eligendi, praesentium veniam deleniti,
        neque qui repudiandae rerum. Iure minima maiores voluptatem mollitia
        earum quos error, eum, qui explicabo dignissimos laboriosam. Porro, sed.
        Repellat et obcaecati eveniet mollitia maxime quis, maiores tempora
        illum nulla, fuga sapiente! Debitis, quaerat? Aliquam quaerat explicabo
        nostrum sunt quas fugit, inventore quia assumenda vel iste ullam minima
        quos reiciendis facilis, in vero quo amet rem ea animi ipsam perferendis
        incidunt excepturi. Placeat, et. Quasi ipsam vero adipisci neque aliquid
        culpa? Sed nobis dolores mollitia optio magni facere! Officia, dicta?
        Molestias magnam numquam praesentium eius, ipsam eligendi, minus veniam
        impedit cum, dolorem tempore voluptatem? Sed, libero! Iusto minima
        voluptate tempora eligendi, temporibus, ullam quos culpa doloribus
        impedit rem perspiciatis, quisquam vero! Et quia tenetur esse, iure
        itaque blanditiis consequuntur, atque impedit at vitae corrupti. Facere
        velit odit sequi quos ut saepe omnis assumenda animi quidem quo? Nam
        ipsa ipsam deserunt totam aliquid, unde voluptate. Provident adipisci
        nostrum commodi nihil ullam odit, quasi nulla quidem.
      </div>
    </Content>
  );
};

export default OpportunityCost;
