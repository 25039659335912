import { ChevronDown } from "lucide-react";
import React from "react";

const Select = ({ label, value }) => {
  return (
    <div className="bg-white dark:bg-slate-900 shadow-sm rounded-full px-4 py-2 h-10 cursor-pointer">
      <div className="flex flex-row items-center">
        <span className="font-medium text-gray-400 dark:text-slate-500 inline-block pr-3 border-r border-gray-200 dark:border-slate-950 mr-3">
          {label}
        </span>
        <span className="font-medium text-blue-900 dark:text-white inline-block pr-3 w-full lg:max-w-[1500px] whitespace-nowrap overflow-hidden text-ellipsis">
          {value}
        </span>
        <button className="text-gray-400 dark:text-slate-500 mt-[2px]">
          <ChevronDown size={14} />
        </button>
      </div>
    </div>
  );
};

Select.defaultProps = {
  label: "Label",
  value: "Value",
};

export default Select;
