import * as yup from "yup";

const schema = yup.object().shape({
  filial: yup.string().required("Obrigatório"),
  local_type: yup.string().required("Obrigatório"),
  cep: yup.string().required("Obrigatório"),
  state: yup.string().required("Obrigatório"),
  city: yup.string().required("Obrigatório"),
  address: yup.string().required("Obrigatório"),
  number: yup.string().required("Obrigatório"),
  street: yup.string().required("Obrigatório"),
});

export default schema;
