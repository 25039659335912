import React from "react";

const labels = [
  {
    name: "1-5 dias",
    color: "bg-[#06B0B4]",
  },
  {
    name: "31-90 dias",
    color: "bg-[#D3C012]",
  },
  {
    name: "91-180 dias",
    color: "bg-[#FF891D]",
  },
  {
    name: "+180 dias",
    color: "bg-[#B10808]",
  },
];

const Labels = () => {
  return (
    <div className="flex flex-row gap-5">
      {labels.map((label, index) => (
        <label key={index} className="flex flex-row items-center">
          <div
            className={`w-[10px] h-[10px] rounded-full ${label.color} mr-2`}
          />
          <span className="inline-block text-sm text-slate-600 dark:text-slate-500">
            {label.name}
          </span>
        </label>
      ))}
    </div>
  );
};

export default Labels;
