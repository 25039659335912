import PieCard from "./pieCard/PieCard";
import LotsList from "./lotsList/LotsList";
import TitleHeader from "./titleHeader/TitleHeader";
import { Content } from "../../../components/layout";
import CardBlockItemVertical from "./cardBlockItemVertical/CardBlockItemVertical";
import Select from "../../../components/forms/select/Select";
import { DollarSign, Gavel, Home } from "lucide-react";
import SalesCard from "./salesCard/SalesCard";
import { useDealershipAuction, useDealershipInfo } from "../../../extras/services/queries";

const Dashboard = () => {
  const auction = useDealershipAuction();
  const yearNow = new Date().getFullYear();
  return (
    <Content title="Início" icon={<Home />}>
      <div className="flex flex-row items-center justify-center">
        <div className="p-5 text-slate-900 dark:text-white w-full max-w-[1185px]">
          <div className="pb-5 flex flex-row justify-end gap-5">
            <Select label="Ano" value={yearNow} />
          </div>

          <div className="flex flex-col lg:flex-row gap-5">
            <PieCard />

            <LotsList />
          </div>


          <div className="w-full mt-6">
            <TitleHeader
              title="Leilão"
              icon={<Gavel className="text-[#D8C830]" />}
            >
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-5">
                <CardBlockItemVertical
                  variant="green"
                  value={auction?.data?.data?.sold}
                  label="Realizado"
                />
                <CardBlockItemVertical
                  variant="yellow"
                  value={auction?.data?.data?.at_auction + auction?.data?.data?.waiting}
                  label="Aguardando"
                />
                <CardBlockItemVertical
                  variant="red"
                  value={auction?.data?.data?.not_listed}
                  label="Em estoque"
                />
              </div>
            </TitleHeader>
          </div>


          <div className="mt-6">
            <TitleHeader
              title="Vendas"
              icon={<DollarSign className="text-[#64D778]" />}
            >
              <SalesCard />
            </TitleHeader>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Dashboard;
