import React from "react";

import { useMediaQuery } from "react-responsive";

import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const CustomPieChart = ({ data, colors }) => {
  const isMd = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const CustomTooltip = ({ payload }) => {
    return (
      <div className="bg-white dark:bg-slate-900 rounded-sm shadow-sm px-2 py-1 border-none">
        <p className="font-medium text-slate-900 dark:text-white pb-0">
          {payload?.[0]?.payload?.name}
        </p>
        <p className="text-sm text-slate-800 dark:text-white">
          $
          {payload?.[0]?.payload?.payload?.value.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
          })}
        </p>
      </div>
    );
  };

  const CustomLegend = ({ payload }) => {
    return (
      <ul>
        {payload.map((entry, index) => {
          return (
            <li key={index} className="flex flex-row mb-2">
              <div
                className={`mt-[6px] mr-2 w-2 h-2 rounded-full`}
                style={{ backgroundColor: entry?.color }}
              />
              <div>
                <p className="font-medium text-sm">{entry?.value}</p>
                <p className="text-slate-400 text-sm">
                  R$
                  {entry?.payload.value.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <ResponsiveContainer height={200}>
      <PieChart>
        <Pie
          stroke="none"
          data={data}
          innerRadius={50}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend
          content={<CustomLegend />}
          layout="vertical"
          verticalAlign={isMd ? "middle" : "bottom"}
          align="right"
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
