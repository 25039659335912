import * as yup from "yup";

const schema = yup.object().shape({
  en_cep: yup.string().required("Obrigatório"),
  en_state_id: yup.number().required("Obrigatório"),
  en_city_id: yup.number().required("Obrigatório"),
  en_address: yup.string().required("Obrigatório"),
  en_number: yup.string().required("Obrigatório"),
  en_district: yup.string().required("Obrigatório"),
});

export default schema;
