import React from "react";

import { Content } from "../../../components/layout";

import { Database, Edit, Trash2 } from "lucide-react";

// img
import Estoque from "../../../extras/imgs/estoque.png";
import Labels from "./labels/Labels";
import SimpleSelect from "../../../components/forms/simpleSelect/SimpleSelect";

import LogoViaConsulti from "../../../extras/imgs/viaconsulti-logo.png";
import { Link } from "react-router-dom";
import { useProduct } from "../../../extras/services/queries";
import ColumnTable from "../../../components/tables/ColumnTable";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment/moment";
import 'moment/locale/pt-br'
moment.locale('pt-br')

const Stock = () => {

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => <td className="w-full px-3 py-2 font-medium text-md dark:text-white">Equipamento</td>,
      cell: (info) => {
        var now = moment(new Date()); //todays date
        var end = moment(info?.row.original.create_time); // another date
        var duration = moment.duration(now.diff(end));
        var days = duration.asDays();

        if (days >= 0 && days <= 5)
          info.row.original.colorBorder = "#06B0B4";
        else if (days > 5 && days <= 90)
          info.row.original.colorBorder = "#D3C012";
        else if (days > 90 && days <= 180)
          info.row.original.colorBorder = "#FF891D";
        else if (days > 180)
          info.row.original.colorBorder = "#B10808";

        return (
          <td className="px-4 py-3 w-[30%] align-top">
            <div className="flex flex-row gap-5 shrink-0">
              <div className="w-[63px] h-[63px] rounded-lg bg-slate-50 dark:bg-slate-700 shrink-0 bg-cover" style={{ backgroundImage: `url(${info.row.original.main_picture?.path})` }} />

              <div className="flex flex-col gap-1 ">
                <Link to={`/estoque/${info.row.original.id}`} className="dark:text-white">
                  {info.getValue()} -
                  ANO {info.row.original.year}
                </Link>
                {info.row.original.at_auction != null &&
                  <div className="flex flex-row gap-5">
                    <button className="text-cyan-500 font-medium">
                      {info.row.original.at_auction?.situation == "to start" && 'A iniciar'}
                      {info.row.original.at_auction?.situation == "running" && 'Ativado'}
                      {info.row.original.at_auction?.situation == "endend" && 'Encerrado'}
                      {info.row.original.at_auction?.situation == "auctioned " && 'Arrematado'}
                    </button>
                    <button className="text-cyan-500 font-medium">
                      Leilão #{info.row.original.at_auction?.lot}
                    </button>
                  </div>}
              </div>
            </div>
          </td>
        )
      },
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: () => <td className="w-full px-3 py-2 font-medium text-md dark:text-white">Descrição</td>,
      cell: (info) => (
        <td className="px-4 py-3 align-top text-left dark:text-white">
          <p>
            <span className="font-medium">Código:</span> {info?.row?.original?.id}
          </p>
          <p>
            <span className="font-medium">Categoria:</span>{" "}
            {info?.row?.original?.sector?.name}/{info?.row?.original?.categorie?.name}
          </p>
          <p>
            <span className="font-medium">Cadastro:</span> {moment(info?.row.original.create_time).format('DD/MM/YYYY')} às {moment(info?.row.original.create_time).format('hh:mm')}
          </p>
          <p>
            <span className="font-medium">Cadastrado {moment(info?.row.original.create_time).fromNow()}</span>
          </p>
        </td>
      ),
    }),
    columnHelper.accessor("city", {
      id: "city",
      header: () => null,
      cell: (info) => {
        const price = (info.row.original.price) ? info.row.original.price : 0;
        return (
          <td className="px-4 py-3 align-top text-right">
            <h2 className="font-medium text-4xl text-blue-900 dark:text-[#06B0B4]">
              R$ {Number(price).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              })}
            </h2>
            <p className="dark:text-slate-500">
              {info.getValue()?.name}/{info.row?.original?.state?.uf}
            </p>
          </td>
        )
      },
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => null,
      cell: (info) => (
        <td className="px-7 py-3 text-right">
          <div className="flex flex-row items-end justify-end gap-5">
            <button onClick={() => console.log(info.getValue())} className="bg-[#E3E7EF] rounded-sm p-2 text-[#002772] hover:opacity-75">
              <Edit />
            </button>
            <button className="bg-[#E3E7EF] rounded-sm p-2 text-[#002772] hover:opacity-75">
              <Trash2 />
            </button>
          </div>
        </td>
      ),
    })]

  return (
    <Content title="Estoque" icon={<Database />}>
      <div className="flex flex-col md:flex-row p-5 gap-2 md:gap-10 md:items-center">
        <div>
          <img src={Estoque} alt="Imagem de referência de estoque na página" />
        </div>
        <div className="flex flex-col gap-3 md:self-end pb-2">
          <h2 className="font-bold text-[#002772] dark:text-[#06B0B4]">
            O que posso fazer aqui?
          </h2>
          <p className="max-w-[600px] text-slate-600 dark:text-white">
            Gerencie seu estoque, veja suas propostas e ofertas recebidas.
          </p>
        </div>
      </div>

      <div className="px-5 mb-5 max-w-[100vh]">
        <div className="bg-white dark:bg-slate-900 shadow-sm rounded-md px-5 py-4 min-w-[1300px]">
          <div className="flex flex-col lg:flex-row items-left lg:items-center justify-between mb-4">
            <span className="font-medium mb-2 lg:mb-0 text-slate-700 dark:text-white">
              Estoque
            </span>
            <Labels />
          </div>
          {/* <div className="flex flex-col lg:flex-row items-left lg:items-center justify-between">
            <span className="mb-2 lg:mb-0 text-slate-500 dark:text-white">
              Todos os produtos (39)
            </span>
            <div className="flex flex-row gap-4">
              <SimpleSelect label="Filial" />
              <SimpleSelect label="Cadastrado por" />
              <SimpleSelect label="Filtro" />
            </div>
          </div> */}
          <ColumnTable
            styleType="borded"
            title="Todos os Produtos"
            columnsData={columns}
            route={useProduct} />
        </div>
      </div>
    </Content>
  );
};

export default Stock;
