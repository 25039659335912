import React, { useMemo } from "react";

import CustomPieChart from "../../../../components/charts/pieChart/PieChart";

import { motion } from "framer-motion";
import { useDealershipStock } from "../../../../extras/services/queries";

const PieCard = () => {
  const stock = useDealershipStock();

  const colors = ["#FA5A5A", "#64D778", "#A064E6"];

  const data = useMemo(() => {
    return [
      { name: "Estoques", value: parseFloat(stock?.data?.data?.not_sold) },
      { name: "Vendidos", value: parseFloat(stock?.data?.data?.sold) },
    ];
  }, [stock]);

  return (
    <motion.div
      initial={{ position: "relative", top: "-100px", opacity: 0 }}
      animate={{ top: "0px", opacity: 1 }}
      className="bg-white dark:bg-slate-900 shadow-sm rounded-md py-4 px-5 w-full md:min-w-[350px] md:w-[55%]"
    >
      <div className="">
        <h1 className="font-medium text-blue-900 dark:text-white text-xl mb-2">
          Minha concessionária
        </h1>
        <p className="text-slate-400/90 mb-3">
          Este gráfico representa o número total de produtos na sua
          concessionária e seu status.
        </p>
      </div>

      <div className="pr-10">
        <div className="mx-auto max-w-[310px]">
          <CustomPieChart colors={colors} data={data} />
        </div>
      </div>
    </motion.div>
  );
};

export default PieCard;
