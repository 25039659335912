import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./deps/range.css";

const Attributes = (props) => {
    const { attributes, onChange, editMode } = props;
    return (
        <table className="">
            <tr>
                <th
                    className="text-sm font-medium text-slate-400 dark:text-white px-3 py-2"
                    align="right"
                ></th>
                <th
                    className="text-sm font-medium text-slate-400 dark:text-white px-3 py-2"
                    align="left"
                >
                    ÓTIMO
                </th>
                <th
                    className="text-sm font-medium text-slate-400 dark:text-white px-3 py-2"
                    align="center"
                >
                    BOM
                </th>
                <th
                    className="text-sm font-medium text-slate-400 dark:text-white px-3 py-2"
                    align="center"
                >
                    REGULAR
                </th>
                <th
                    className="text-sm font-medium text-slate-400 dark:text-white px-3 py-2"
                    align="right"
                >
                    RUIM
                </th>
            </tr>
            {attributes?.map((item, index) => {
                return (<tr>
                    <th
                        className="text-sm font-medium text-slate-400 dark:text-white px-3 py-2 min-w-[250px]"
                        align="right"
                    >
                        {item?.name}:
                    </th>
                    <td colSpan={4} className="px-3 py-2 min-w-[580px]">
                        <RangeSlider
                            className="single-thumb"
                            id="range-slider-yellow"
                            onInput={(res) => { onChange(index, res[1]) }}
                            defaultValue={editMode ? [0, parseInt(item.score)] : [0, 0]}
                            max={item?.max_score}
                            thumbsDisabled={[true, false]}
                            rangeSlideDisabled={true}
                        />
                    </td>
                </tr>)
            })}
        </table>
    )
}

export default Attributes;