import React from "react";

const RadioGroup = ({ children, label, error }) => {
  return (
    <div className="flex flex-col">
      <span className="font-normal text-slate-700 mb-1 inline-block text-sm ml-1 dark:text-white">
        {label}
      </span>
      <div className="flex flex-col md:flex-row gap-5 min-h-[50px] items-center">
        {children}
      </div>
      {error && <span className="text-red-500 text-sm">{error?.message}</span>}
    </div>
  );
};

export default RadioGroup;
