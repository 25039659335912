import React from "react";

import Header from "../header/Header";

import { motion } from "framer-motion";

const Content = ({ children, title, icon }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col w-full"
    >
      <Header title={title} icon={icon} />

      <div className="overflow-y-auto h-full">{children}</div>
    </motion.div>
  );
};

export default Content;
