import React from "react";

import Menu from "./menu/Menu";
import Logo from "../../logo/Logo";
import { Plus, X } from "lucide-react";
import { useMediaQuery } from "react-responsive";
import LogoWhite from "../../logoWhite/LogoWhite";
import useTheme from "../../../extras/hooks/useTheme";
import useDrawer from "../../../extras/providers/DrawerProvider";

import { motion } from "framer-motion";

const SideMenu = () => {
  const { theme, prefersDarkScheme } = useTheme();

  const { open, setOpen } = useDrawer();

  const isMd = useMediaQuery({
    query: "(min-width: 768px)",
  });

  if (open || isMd)
    return (
      <div className="">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed md:hidden w-full bottom-0 top-0 bg-black/75 z-30"
        />

        <div className="fixed md:relative shadow-sm bg-white dark:bg-slate-900 md:dark:bg-slate-900 w-[290px] h-screen shrink-0 flex flex-col z-40">
          <button
            className="text-white fixed md:hidden right-5 top-5 z-30"
            onClick={() => setOpen(!open)}
          >
            <X />
          </button>

          <div className="p-5 pb-0 pt-7">
            <div className="flex flex-row items-center justify-center">
              {theme === "light" ? (
                <Logo />
              ) : theme === "dark" || prefersDarkScheme ? (
                <LogoWhite />
              ) : (
                <Logo />
              )}
            </div>
          </div>

          <Menu />
        </div>
      </div>
    );

  // else
  return null;
};

export default SideMenu;
