import React, { useEffect, useState } from "react";

import { Gavel } from "lucide-react";
import Button from "../../../../components/button/Button";
import Lots from "../lots/Lots";

import { motion } from "framer-motion";
import { useProductAuction } from "../../../../extras/services/queries";
import LotsItem from "../lotsItem/LotsItem";

const LotsList = () => {
  const { mutate } = useProductAuction();
  const [data, setData] = useState([]);

  useEffect(() => {
    mutate({ situation: 3 }, {
      onSuccess: (res) => {
        setData(res?.data?.data);
      }
    })
  }, [])

  return (
    <motion.div
      initial={{ position: "relative", top: "-100px", opacity: 0 }}
      animate={{ top: "0px", opacity: 1 }}
      className="w-full bg-white dark:bg-slate-900 shadow-sm rounded-md py-4 px-5"
    >
      <div className="flex flex-col md:flex-row justify-between md:items-center mb-4 pb-3 border-b border-gray-200/60 dark:border-slate-800 gap-5 md:gap-0">
        <div className="flex flex-row items-center">
          <Gavel className="text-[#64D778]" />
          <h1 className="font-medium text-blue-900 dark:text-white text-xl ml-5">
            Lotes em leilão ({data?.length})
          </h1>
        </div>
        <div className="hidden md:inline-block">
          <Button>Emitir Relatório</Button>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 min-h-[150px]">
          {data?.map((item, i) => {
            return <LotsItem item={item} />;
          })}
        </div>
        <div className="mt-5 flex flex-row justify-end 2xl:hidden gap-3">
          <Button variant="transparent">Ver mais</Button>
          <Button>Emitir Relatório</Button>
        </div>
      </div>
    </motion.div>
  );
};

export default LotsList;
