//Transform get params with array, remove nulls values
function buildParams(data) {
    const params = new URLSearchParams()

    Object.entries(data).forEach(([key, value]) => {

        if (Array.isArray(value)) {
            if (!value.includes(undefined))
                value.forEach(value => params.append(key + '[]', value?.toString()))
        } else {
            if (value !== null && !value !== undefined)
                params.append(key, value?.toString())
        }
    });

    return params?.toString();
}

//Transforma GET URL em objeto => aceita arrays e outras variações
const getParamsAsObject = function (query) {

    query = query.substring(query.indexOf('?') + 1);

    var re = /([^&=]+)=?([^&]*)/g;
    var decodeRE = /\+/g;

    var decode = function (str) {
        return decodeURIComponent(str.replace(decodeRE, " "));
    };

    var params = {},
        e;
    while (e = re.exec(query)) {
        var k = decode(e[1]),
            v = decode(e[2]);
        if (k.substring(k.length - 2) === '[]') {
            k = k.substring(0, k.length - 2);
            (params[k] || (params[k] = [])).push(v);
        } else params[k] = v;
    }

    var assign = function (obj, keyPath, value) {
        var lastKeyIndex = keyPath.length - 1;
        for (var i = 0; i < lastKeyIndex; ++i) {
            var key = keyPath[i];
            if (!(key in obj))
                obj[key] = {}
            obj = obj[key];
        }
        obj[keyPath[lastKeyIndex]] = value;
    }

    for (var prop in params) {
        var structure = prop.split('[');
        if (structure.length > 1) {
            var levels = [];
            structure.forEach(function (item, i) {
                var key = item.replace(/[?[\]\\ ]/g, '');
                levels.push(key);
            });
            assign(params, levels, params[prop]);
            delete (params[prop]);
        }
    }
    return params;
};

const getArrayQueryParams = (queryParams) => {
    const arrayParams = {};

    Object.keys(queryParams).forEach((paramName) => {
        const paramValue = queryParams[paramName];
        // Verifica se o valor do parâmetro é uma string e se começa com o formato desejado
        if (typeof paramValue === 'string' && paramName.includes(`[]`)) {
            const values = paramValue.replace(`${paramName}=`, '').split('&');
            arrayParams[paramName.replace('[]', '')] = parseInt(values);
        }
    });

    return arrayParams;
};

function removerValoresNulos(objeto) {
    // Verifica se o objeto é do tipo objeto
    if (typeof objeto !== 'object' || objeto === null) {
        return objeto;
    }

    // Para cada chave no objeto
    for (let chave in objeto) {
        // Verifica se o valor é nulo ou um array com valor nulo
        if (objeto[chave] === undefined || objeto[chave] === null || (Array.isArray(objeto[chave]) && objeto[chave].every(elemento => elemento === null))) {
            // Remove a chave do objeto
            delete objeto[chave];
        } else if (Array.isArray(objeto[chave])) {
            // Se o valor for um array, chama a função recursivamente
            objeto[chave] = objeto[chave].map(elemento => removerValoresNulos(elemento));
        } else if (typeof objeto[chave] === 'object') {
            // Se o valor for um objeto, chama a função recursivamente
            objeto[chave] = removerValoresNulos(objeto[chave]);
        }
    }

    return objeto;
}

const extrairIdVideo = (link) => {
    // Expressão regular para extrair o ID do vídeo do YouTube
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    if (!link)
        return null;

    // Testar a regex no link do YouTube
    const match = link?.match(regex);

    // Verificar se houve correspondência e retornar o ID do vídeo, se encontrado
    if (match && match[1]) {
        return match[1];
    } else {
        return null; // Retorna null se não encontrar um ID de vídeo válido
    }
};

export {
    getArrayQueryParams,
    removerValoresNulos,
    buildParams,
    getParamsAsObject,
    extrairIdVideo
};