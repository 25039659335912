import { Download, Plus } from "lucide-react";
import React from "react";
import Button from "../../../../components/button/Button";

const Autorization = () => {
  return (
    <div className="mt-5">
      <div>
        <ul className="flex flex-row gap-5">
          <li className="w-full">
            <button className="flex flex-col lg:flex-row items-center gap-5 justify-center w-full py-10 rounded-md bg-white shadow-md">
              <span className="text-[#06B0B4]">
                <Download />
              </span>
              <span className="text-[#002772] text-sm font-semibold">
                Autorização de carregamento por terceiros
              </span>
            </button>
          </li>
          <li className="w-full">
            <button className="flex flex-row items-center gap-5 justify-center w-full py-10 rounded-md bg-white shadow-md">
              <span className="text-[#06B0B4]">
                <Download />
              </span>
              <span className="text-[#002772] text-sm font-semibold">
                Autorização de venda
              </span>
            </button>
          </li>
        </ul>
      </div>

      <div className="flex flex-row justify-between items-center py-3">
        <h1 className="text-[#002772] text-sm font-semibold text-left px-7">
          Total de itens (23)
        </h1>
        <Button>
          <div className="flex flex-row items-center gap-2">
            <Plus size={16} />
            Enviar novo arquivo
          </div>
        </Button>
      </div>

      <table className="w-full shadow-md rounded-md">
        <thead>
          <tr className="shadow-sm">
            <th className="text-slate-400 text-sm font-semibold text-left px-7 py-4">
              Observação
            </th>
            <th className="text-slate-400 text-sm font-semibold text-left px-7 py-4">
              Data
            </th>
            <th className="text-slate-400 text-sm font-semibold text-right px-7 py-4">
              Download
            </th>
          </tr>
        </thead>
        <tbody>
          {/* <tr className="border-b border-slate-50"> */}
          <tr className="">
            <td className="px-7 py-3 text-slate-400 text-sm">
              Colheitadeira CASE AF2166 com plataforma Flexível 20 - Ano 1997
            </td>
            <td className="px-7 py-3 text-slate-400 text-sm">10/09/2023</td>
            <td className="px-7 py-3 text-right">
              <button className="bg-[#E3E7EF] rounded-sm p-2 text-[#002772] hover:opacity-75 ">
                <Download />
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="pt-3 pb-2">
        <div>
          <label className="">
            <span className="pl-7 pr-3 py-3 text-slate-400 text-sm">
              Itens por página
            </span>

            <select className="text-[#002772] font-semibold text-sm pr-10">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </label>

          <span className="pl-7 pr-3 py-3 text-slate-400 text-sm">
            1-5 de 30
          </span>
        </div>
      </div>
    </div>
  );
};

export default Autorization;
