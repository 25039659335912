import { Minus, Plus } from "lucide-react";
import { useState } from "react"

export default function CardHide({ children, title, defaultOpen = false, className = '' }) {
    const [open, setOpen] = useState(defaultOpen);
    return (
        <div className={`grid lg:grid-cols-2 gap-2 border bg-gray-100/10 rounded p-2 mb-2 transition-all duration-500 ease-out ${open ? 'max-h-[600px]' : 'max-h-[40px] !overflow-hidden' } ${className}`}>
            <div className="flex justify-between col-span-2 items-center cursor-pointer" onClick={() => setOpen(!open)}>
                <div className="text-center font-bold w-full">{title}</div>
                <div className="px-2 cursor-pointer" onClick={() => setOpen(!open)}>
                    {open ? <Minus /> : <Plus />}
                </div>
            </div>
            {children}
        </div>
    )
}
