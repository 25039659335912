import React, { useEffect, useState } from "react";

// Components
import Images from "./Images";
import AllImages from "./AllImages";
import { useOutletContext, useParams } from "react-router-dom";
import { useAddImage, useInfoProduct } from "../../../../extras/services/queries";
import { toast } from "react-toastify";
import { getCookie } from "react-use-cookie";
import { set } from "react-hook-form";

const EditPhotos = () => {
  const params = useParams();

  const { mutate, isLoading } = useInfoProduct();
  const [productImage, setProductImage] = useState([]);
  const [images, setImages] = useState([]);
  const [mainPicture, setMainPicture] = useState(-1);

  const getImages = () => {
    mutate({ id: params?.id }, {
      onSuccess: (res) => {
        setProductImage(res?.data?.images)
      }
    })
  }
  useEffect(() => {
    getImages();
  }, [params?.id])

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    images?.forEach(async (file, index) => {
      formData.append("images[" + index + "][image]", file);
      if (mainPicture === index)
        formData.append("images[" + index + "][main]", true);
    });

    let bearerToken = "";

    try {
      bearerToken = getCookie("authorization");
    } catch (e) {
      new Error(e);
    }

    await fetch(`${process.env.REACT_APP_BASE_API_URL}/product/${params?.id}/image/add`,
      {
        method: "POST",
        headers: {
          contentType: false,
          accept: 'application/json',
          mimeType: "multipart/form-data",
          Authorization: "Bearer " + bearerToken,
          "Token-Access": process.env.REACT_APP_BASE_API_KEY,
        },
        body: formData,
      }
    ).then((data) => {
      if (data.status == 200) {
        toast.success("Imagem adicionada com sucesso!");
        getImages();
        setImages([]);
      } else {

        toast.error(`Erro ${data.status}: ${data.statusText}`);
      }
    });
  };

  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-5">
      <AllImages productImage={productImage} setProductImage={setProductImage} />
      <Images images={images} setImages={setImages} setMainPicture={setMainPicture} mainPicture={mainPicture} />
      <div>
        <button
          type="submit"
          className="font-medium bg-[#002772] text-white px-7 py-2 rounded-full hover:opacity-80"
        >
          Enviar
        </button>
      </div>
    </form>
  );
};

export default EditPhotos;
