import React, { useEffect, useState } from "react";

import AddMoreImages from "./AddMoreImages";
import SingleImage from "./SingleImage";

const StepPictures = ({ i, currentStep, setCurrentStep, setImages, mainPicture, setMainPicture }) => {
  const [error, setError] = useState("");
  const [extraImages, setExtraImages] = useState([]);
  const [frontImage, setFrontImage] = useState("");
  const [leftSideImage, setLeftSideImage] = useState("");
  const [rightSideImage, setRightSideImage] = useState("");
  const [backImage, setBackImage] = useState("");

  const validateImages = () => {
    if (!frontImage || !leftSideImage || !rightSideImage || !backImage) {
      setError("Adicione as imagens do produto!");
    } else {
      setImages([frontImage, leftSideImage, rightSideImage, backImage, ...extraImages]);
      setCurrentStep((currentStep) => currentStep + 1);
    }
  };

  useEffect(() => {
    setError("");
  }, [extraImages, frontImage, leftSideImage, rightSideImage, backImage]);

  return (
    <div className="pt-5">
      <div className="mb-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5">
        <SingleImage
          title="Frente do produto"
          selectedImage={frontImage}
          setSelectedImage={setFrontImage}
          mainPicture={mainPicture}
          setMainPicture={setMainPicture}
          customIndex={0}
        />
        <SingleImage
          title="Lateral esquerda"
          selectedImage={leftSideImage}
          setSelectedImage={setLeftSideImage}
          mainPicture={mainPicture}
          setMainPicture={setMainPicture}
          customIndex={1}
        />
        <SingleImage
          title="Lateral direita"
          selectedImage={rightSideImage}
          setSelectedImage={setRightSideImage}
          mainPicture={mainPicture}
          setMainPicture={setMainPicture}
          customIndex={2}
        />
        <SingleImage
          title="Traseira do produto"
          selectedImage={backImage}
          setSelectedImage={setBackImage}
          mainPicture={mainPicture}
          setMainPicture={setMainPicture}
          customIndex={3}
        />
      </div>

      {error && <div className="text-red-500 pb-2">{error}</div>}

      <AddMoreImages images={extraImages} setImages={setExtraImages} />

      <button
        type="button"
        onClick={validateImages}
        className="font-medium bg-[#002772] text-white px-7 py-2 rounded-full hover:opacity-80 mt-5 mb-2"
      >
        Próximo
      </button>
    </div>
  );
};

export default StepPictures;
