import {
  CircleDot,
  Command,
  Database,
  DollarSign,
  FilePieChart,
  Files,
  Home,
  UserCog2,
  Settings,
} from "lucide-react";

const links = [
  {
    name: "Home",
    icon: <Home />,
    to: "/dashboard",
  },
  {
    name: "Estoque",
    icon: <Database />,
    to: "/estoque",
  },
  {
    name: "Vendas",
    icon: <DollarSign />,
    to: "/vendas",
  },
  // {
  //   name: "Documentos",
  //   icon: <Files />,
  //   to: "/documentos",
  // },
  // {
  //   name: "Relatórios",
  //   icon: <FilePieChart />,
  //   to: "/relatorios",
  // },
  // {
  //   name: "Gerenciar acessos",
  //   icon: <UserCog2 />,
  //   to: "/gerenciar-acessos",
  // },
  // {
  //   name: "Custo de oportunidade",
  //   icon: <CircleDot />,
  //   to: "/custo-de-oportunidade",
  // },
  {
    name: "Via Consulti",
    icon: <Command />,
    to: "/via-consulti",
  },
  {
    name: "Meu perfil",
    icon: <Settings />,
    to: "/configuracoes",
  }
];

export default links;
