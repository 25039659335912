import { Download } from "lucide-react";
import React from "react";

const AuctionAnnouncement = () => {
  return (
    <div>
      <ul className="flex flex-row gap-5">
        <li className="w-full">
          <button className="flex flex-col lg:flex-row items-center gap-5 justify-center w-full py-10 rounded-md bg-white shadow-md">
            <span className="text-[#06B0B4]">
              <Download />
            </span>
            <span className="text-[#002772] text-sm font-semibold">
              Edital do leilão
            </span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default AuctionAnnouncement;
