import React, { useCallback, useState } from "react";

import { Star, Trash2, Upload } from "lucide-react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import ConfirmAction from "../../../../components/confirmAction/ConfirmAction";

const Images = ({ images, setImages, setMainPicture, mainPicture }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          uuid: uuidv4(),
        }),
      ),
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg"],
    },
    maxFiles: 5,
    multiple: true,
  });

  const removeImageFromArray = (uuid) => {
    const newFiles = images.filter((item) => item.uuid !== uuid);
    setImages(newFiles);
    if (newFiles?.length == 0)
      setMainPicture(-1);
  };

  const Preview = images.map((file, index) => (
    <div
      key={index}
      className="group relative min-h-[200px] rounded-2xl overflow-hidden bg-slate-50 dark:bg-slate-800 flex flex-row items-center justify-center"
    >
      {" "}
      {/* Preview imagem */}
      <img
        className="h-full w-full object-cover object-center"
        src={file.preview}
        alt="Imagem do equipamento"
      />
      <div className="hidden w-full h-full group-hover:flex flex-row items-center justify-center bg-slate-900/50 absolute">
        <div className="top-0 absolute">
          <ConfirmAction confirmColor="yellow" onClick={() => setMainPicture(index)} className="p-2 cursor-pointer">
            <Star className={`${mainPicture == index && 'text-yellow-500'} hover:text-yellow-500`} />
          </ConfirmAction>
        </div>
        <button
          onClick={() => removeImageFromArray(file.uuid)}
          className="w-[100px] h-[100px] rounded-full flex flex-row items-center justify-center bg-slate-900/50 hover:bg-slate-900/70 text-white"
        >
          <Trash2 />
        </button>
      </div>
    </div>
  ));

  const classes = isDragActive
    ? "cursor-pointer min-h-[200px] max-h-[200px] rounded-2xl overflow-hidden bg-cyan-500/5 dark:bg-cyan-600/5 border-2 border-cyan-500 border-dashed dark:bg-slate-700 hover:opacity-90 text-sm text-slate-800 flex flex-col gap-4 items-center justify-center"
    : "cursor-pointer min-h-[200px] rounded-2xl overflow-hidden bg-slate-50 dark:bg-slate-800 hover:opacity-90 text-sm text-slate-800 flex flex-col gap-4 items-center justify-center";

  return (
    <div className="flex flex-col">
      {/* Imagem de capa */}
      <h1 className="text-slate-700 dark:text-white font-normal text-md mb-3">
        Anexar fotos
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5">
        {images && Preview}

        <div {...getRootProps()} className={classes}>
          {" "}
          {/* Preview imagem */}
          <Upload className="text-cyan-600" />
          <p className="text-center dark:text-white">
            Arraste as imagens, ou então <br />{" "}
            <span className="underline font-medium text-cyan-600">
              clique aqui
            </span>{" "}
            para selecionar.
          </p>
          <p className="text-center dark:text-slate-600 text-slate-400">
            Somente formatos: <br /> .png, .jpg, .jpeg. Max: 5
          </p>
          <input {...getInputProps()} />
        </div>
      </div>
    </div>
  );
};

export default Images;
