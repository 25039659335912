import React from "react";

import { Content } from "../../../components/layout";

import { Settings } from "lucide-react";
import ThemeSwitcher from "../../../components/layout/sideMenu/themeSwitcher/ThemeSwitcher";
import FormInput from "../../../components/forms/formInput/FormInput";
import { useForm } from "react-hook-form";

const Configs = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
  });

  return (
    <Content title="Configurações" icon={<Settings />}>
      <div className="p-5 flex flex-row justify-center">
        <div className="md:mt-10 rounded-lg bg-white dark:bg-slate-900 w-full max-w-[550px] shadow-sm">
          <ul>
            <li className="p-5 flex flex-row justify-between w-full">
              <span className="text-gray-500 dark:text-white inline-block ">
                Meu perfil
              </span>
            </li>
          </ul>
          <div className="p-5">
            <FormInput
              label="E-mail"
              placeholder="Insira seu e-mail"
              name="email"
              register={register}
              error={errors?.email} />
            <FormInput
              label="Senha"
              placeholder="Insira sua senha"
              type="password"
              name="password"
              register={register}
              error={errors?.password} />
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Configs;
