import { Check, Info } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDealershipSold } from "../../../../extras/services/queries";

const months = [
  "JAN",
  "FEV",
  "MAR",
  "ABR",
  "MAI",
  "JUN",
  "JUL",
  "AGO",
  "SET",
  "OUT",
  "NOV",
  "DEZ",
];

const SalesCard = () => {
  const { mutate, isPending } = useDealershipSold();
  const monthNow = new Date().getMonth();

  const [currentMonth, setCurrentMonth] = useState(months[monthNow]);
  const [data, setData] = useState({});

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const month = months.indexOf(currentMonth);
    mutate({ year: currentYear, month: (month + 1) }, {
      onSuccess: (res) => {
        setData(res.data);
      }
    });
  }, [currentMonth])

  const isActive = (month) =>
    currentMonth === month
      ? "text-green-400 bg-white px-6 py-2 flex flex-row items-center justify-center rounded-full font-medium text-sm"
      : "text-white bg-transparent hover:text-green-400 hover:bg-white px-6 py-2 flex flex-row items-center justify-center rounded-full font-medium text-sm";

  return (
    <div className="flex flex-col lg:flex-row rounded-lg overflow-hidden gap-5 shadow-sm bg-white dark:bg-slate-900">
      <div className="bg-green-400 px-5 py-4 grid grid-cols-4 gap-3">
        {months.map((month, index) => {
          return (
            <button
              key={index}
              className={isActive(month)}
              onClick={() => setCurrentMonth(month)}
            >
              {month}
            </button>
          );
        })}
      </div>

      <div className="pl-0 px-5 py-4 w-full flex flex-col lg:flex-row items-center justify-center xl:justify-normal">
        <div className="ml-10  mr-10 flex flex-row mb-5 lg:mb-0">
          <div className="w-20 h-20 flex flex-row items-center justify-center rounded-full bg-green-500/5 text-[#64D778]">
            <Check size={34} />
          </div>

          <div className="pl-10 border-0 lg:border-r border-slate-100 dark:border-slate-800 pr-10">
            <h2 className="text-[#2D374B] dark:text-white text-4xl font-bold mr-10">
              {isPending ? '...' : data?.total}
            </h2>
            <div className="flex flex-col">
              <span className="font-medium text-[#64D778]">Vendas totais</span>
              <span className="text-[#828B9D] dark:text-slate-400">Mensal</span>
            </div>
          </div>
        </div>

        <div className="pb-5 pt-5 lg:p-0 flex flex-col gap-3">
          <Info className="text-[#64D778]" />
          <p className="text-slate-500 dark:text-slate-400">
            27% do seu estoque está 25% acima do Valor Consulti
          </p>
          <div className="flex flex-row items-center gap-3">
            <button className="text-white bg-[#64D778] rounded-full p-2 w-[100px] font-medium text-sm hover:opacity-80">
              Avaliar
            </button>
            <button className="text-[#64D778] bg-white shadow-sm rounded-full p-2 w-[240px] font-medium text-sm hover:opacity-80">
              Avaliar propostas recebidas
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesCard;
