import React from "react";

const FormRadioButton = ({ name, value, label, register }) => {
  return (
    <label className="flex flex-row gap-2">
      <input type="radio" value={value} {...register(name)} />
      <span className="text-slate-700 dark:text-white">{label}</span>
    </label>
  );
};

export default FormRadioButton;
