import React from "react";
import Spinner from "../../layout/utils/Spinner";

const FormSelect = ({ label, name, register, options, error, required, isLoading, disabled }) => {
  const classes = error
    ? "border border-red-500 rounded-lg w-full"
    : "border border-slate-100 rounded-lg w-full dark:border-slate-800";

  return (
    <label className="block mb-3">
      <span className="font-bold text-slate-700 mb-1 inline-block text-sm ml-1 dark:text-white">
        {label} {required && <span className="text-red-500">*</span>}
      </span>
      <div className={classes}>
        {isLoading ? <div className="py-3 px-4"><Spinner /></div> :
          <select
            name={name}
            disabled={(isLoading || disabled)}
            {...register(name, { defaultValue: "" })}
            className="py-3 px-4 rounded-lg dark:bg-transparent text-slate-700 bg-[url('./extras/imgs/arrow-down.svg')] bg-no-repeat bg-right border-r-[16px] border-transparent w-full cursor-pointer outline-none focus:bg-slate-100"
          >
            <option value="">Selecione</option>
            {options?.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </select>}
      </div>
      {error?.message && (
        <span className="text-red-500 text-sm">{error?.message}</span>
      )}
    </label>
  );
};

export default FormSelect;
