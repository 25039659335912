import { Edit, Trash2 } from "lucide-react";
import React, { useState } from "react";
import ColumnTable from "../../../../components/tables/ColumnTable";
import { useListBranchs } from "../../../../extras/services/queries";
import { createColumnHelper } from "@tanstack/react-table";
import ModalCreate from "./deps/ModalCreate";
import ModalUpdate from "./deps/ModalUpdate";

const Filiais = () => {
  const [showUpdate, setShowUpdate] = useState(false);
  const [branchId, setBranchId] = useState(null);


  const editBranch = (branch_id) => {
    setBranchId(branch_id);
    setShowUpdate(true);
  }

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => <div>Nome</div>,
      cell: (info) => (
        <div>
          {info.getValue()}
        </div>
      ),
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => null,
      cell: (info) => (
        <td className="px-7 py-3 text-right">
          <div className="flex flex-row items-end justify-end gap-5">
            <button onClick={() => editBranch(info.getValue())} className="bg-[#E3E7EF] rounded-sm p-2 text-[#002772] hover:opacity-75">
              <Edit />
            </button>
            <button className="bg-[#E3E7EF] rounded-sm p-2 text-[#002772] hover:opacity-75">
              <Trash2 />
            </button>
          </div>
        </td>
      ),
    })]
  return (
    <>
      <ColumnTable
        title="Filiais"
        route={useListBranchs}
        columnsData={columns}
        ModalCreate={ModalCreate}
      />
      <ModalUpdate show={showUpdate} setShow={setShowUpdate} branchId={branchId} />
    </>
  );
};

export default Filiais;
