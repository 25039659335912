import React, { useEffect, useState } from "react";

import { Outlet, useOutletContext, useParams } from "react-router-dom";

import Menu from "./menu/Menu";


const EditTemplate = () => {
  const [product, setProduct] = useOutletContext();

  return (
    <div className="px-5">
      <div className="w-full bg-white dark:bg-slate-900 shadow-sm rounded-md p-5">
        {/* Menu */}
        <div>
          <Menu />
        </div>
        <div className="pt-5">
          {/* Content */}
          <Outlet context={[product, setProduct]} />
        </div>
      </div>
    </div>
  );
};

export default EditTemplate;
