import React from "react";
import { useOutletContext } from "react-router-dom";

const InAuction = () => {
  const [product, setProduct] = useOutletContext();
  console.log(product?.at_auction)
  return (
    <div className="px-5">
      <div className="overflow-x-auto w-full bg-white dark:bg-slate-900 shadow-sm rounded-md py-4 px-5">
        {product?.at_auction === null ? `O produto ainda não está em leilão` : 'Em leilãooo'}
      </div>
    </div>
  );
};

export default InAuction;
