import React, { createContext, useEffect, useState } from "react";
import { getCookie, removeCookie } from "react-use-cookie";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext({});

export const AuthProvider = (props) => {

  const [user, setUser] = useState(null);

  useEffect(() => {
    const verify = async () => {
      const authorization = getCookie('authorization');
      
      if (authorization) {
        const infos = jwtDecode(authorization);
        setUser(infos);
      } else {
        setUser(null);
        removeCookie("authorization");
      }
    }

    verify();
  }, []);

  
  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => React.useContext(AuthContext);