import React, { useCallback, useEffect, useState } from "react";

import { Star, Trash2, Upload } from "lucide-react";
import { useDropzone } from "react-dropzone";

import { v4 as uuidv4 } from "uuid";
import { useOutletContext } from "react-router-dom";
import { useDeleteImage, useMainImage } from "../../../../extras/services/queries";
import ConfirmAction from "../../../../components/confirmAction/ConfirmAction";
import { toast } from "react-toastify";

const AllImages = ({ productImage }) => {
  const [product, setProduct] = useOutletContext();
  const { mutate: mutateDelete, isPending: isLoadingDelete } = useDeleteImage();
  const { mutate: mutateMain, isPending: isLoadingMain } = useMainImage();

  const [images, setImages] = useState([]);
  const [mainPicture, setMainPicture] = useState(-1);

  const removeImage = (index, imageId) => {
    const filterImage = [...images];
    mutateDelete({ product_id: product?.id, id: imageId }, {
      onSuccess: (res) => {
        toast.success("Imagem deletada com sucesso!");
        filterImage.splice(index, 1);
        setImages(filterImage);
      },
      onError: (res) => {
        toast.error("Erro ao tentar excluir imagem!");
      }
    })

    if (filterImage?.length == 0)
      setMainPicture(-1);
  };

  const mainImage = (index, imageId) => {
    mutateMain({ product_id: product?.id, id: imageId }, {
      onSuccess: (res) => {
        toast.success("Imagem definida como capa!");
        setMainPicture(index)
      },
      onError: (res) => {
        toast.error("Erro ao tentar alterar a capa!");
      }
    })
  };

  useEffect(() => {
    if (productImage) {
      setImages(productImage);
      productImage.map((value, index) => {
        if (value?.main)
          setMainPicture(index)
      })
    }
  }, [JSON.stringify(productImage)])

  return (
    <div className="flex flex-col">
      {/* Imagem de capa */}
      <h1 className="text-slate-700 dark:text-white text-md mb-3 font-bold">
        Todas as fotos
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5">
        {images.map((item, index) => {
          return (
            <div className="group relative min-h-[200px] max-h-[200px] rounded-2xl overflow-hidden bg-slate-50 dark:bg-slate-800 flex flex-row items-center justify-center">
              {" "}
              {/* Preview imagem */}
              <img
                className="h-full w-full object-cover object-center"
                src={item.path}
                alt="Imagem do equipamento"
              />
              <div className="hidden w-full h-full group-hover:flex flex-row items-center justify-center bg-slate-900/50 absolute">
                <div className="top-0 absolute">
                  <ConfirmAction confirmColor="yellow" onClick={() => mainImage(index, item.id)} className="p-2 cursor-pointer">
                    <Star className={`${mainPicture == index && 'text-yellow-500'} hover:text-yellow-500`} />
                  </ConfirmAction>
                </div>
                <ConfirmAction onClick={() => removeImage(index, item.id)}>
                  <div
                    className="w-[100px] h-[100px] rounded-full flex flex-row items-center justify-center bg-slate-900/50 hover:bg-slate-900/70 text-white"
                  >
                    <Trash2 />
                  </div>
                </ConfirmAction>
              </div>
            </div>)
        })}


      </div>
    </div>
  );
};

export default AllImages;
