import React from "react";
import Spinner from "../layout/utils/Spinner";

const variants = {
  normal:
    "disabled:opacity-75 bg-[#002772] dark:bg-[#06b0b4] text-white h-10 px-5 rounded-full text-sm font-medium hover:opacity-90",
  transparent:
    "disabled:opacity-75 bg-transparent hover:bg-[#002772] hover:text-white text-[#002772] dark:hover:bg-[#06b0b4] dark:text-white h-10 px-5 rounded-full text-sm font-medium",
};

const Button = ({ children, variant, loading, disabled, ...props }) => {
  return <button {...props} className={variants[variant]} disabled={(disabled || loading)}>
    {loading ? <Spinner /> : children}
  </button>;
};

Button.defaultProps = {
  children: "Button title",
  variant: "normal",
};

export default Button;
