import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useInfoUser } from '../../extras/services/queries';
import LayoutLoading from '../../components/layout/layoutLoading/LayoutLoading';


const ProtectedRoute = ({ element }) => {
    const { mutate } = useInfoUser();
    const [isLoading, setIsLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {

        mutate(null, {
            onSuccess: (res) => {
                setIsAuth(true);
                setIsLoading(false);
            },
            onError: (res) => {
                setIsAuth(false);
                setIsLoading(false);
            }
        })
    }, [])

    if (isLoading)
        return <LayoutLoading />;

    if (!isAuth)
        return <Navigate to="/login" replace />;



    return element;
};

export default ProtectedRoute;
