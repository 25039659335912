import React from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "../../../../extras/schemas/description.schema";
import FormTextArea from "../../../../components/forms/formTextArea/FormTextArea";

const EditDescription = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => console.log(data);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTextArea
          label="Descrição"
          placeholder="Insira a descrição"
          name="description"
          register={register}
          error={errors?.description}
        />
        <button
          type="submit"
          className="font-medium bg-[#002772] text-white px-7 py-2 rounded-full hover:opacity-80"
        >
          Enviar
        </button>
      </form>
    </div>
  );
};

export default EditDescription;
