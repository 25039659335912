import React from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "../../../../extras/schemas/markAsSold.schema";
import FormInput from "../../../../components/forms/formInput/FormInput";
import FormTextArea from "../../../../components/forms/formTextArea/FormTextArea";
import { useSoldProduct } from "../../../../extras/services/queries";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";

const MarkAsSold = () => {
  const [product, setProduct] = useOutletContext();
  const { mutate } = useSoldProduct();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    mutate({ ...data, id: product?.id }, {
      onSuccess: (res) => {
        toast.success("Produto marcado como vendido");
      }
    })
  };

  return (
    <div className="px-5">
      <div className="overflow-x-auto w-full bg-white dark:bg-slate-900 shadow-sm rounded-md py-4 px-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            type="date"
            label="Data da venda"
            placeholder="Insira a data da venda"
            name="sale_time"
            register={register}
            error={errors?.sale_time}
          />
          <FormInput
            label="Valor da venda"
            placeholder="Insira o valor da venda"
            name="value"
            register={register}
            error={errors?.value}
          />
          <FormTextArea
            label="Observação"
            placeholder="Insira a observação"
            name="observation"
            register={register}
            error={errors?.observation}
          />
          <button
            type="submit"
            className="font-medium bg-[#002772] text-white px-7 py-2 rounded-full hover:opacity-80"
          >
            Salvar
          </button>
        </form>
      </div>
    </div>
  );
};

export default MarkAsSold;
